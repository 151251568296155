<template>
  <Popup :showPopup="showModal" @close="showModal = false" :header="$t('error.popup.header')">
    <template v-slot:content>
      <div v-html="$t('error.popup.content')"></div>
    </template>

    <template v-slot:footer>
      <Button primary @click="showModal = false" msg="OK" />
    </template>
  </Popup>
</template>

<script lang="ts" setup>
import Popup from '../common/Popup.vue'
import Button from '../common/Button.vue'
import { ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import { eventBus } from '../../eventBus'

const showModal = ref(false)

const bus = useEventBus(eventBus)
bus.on((event) => {
  if (event.name === 'apiError') {
    showModal.value = true
  }
})
</script>

<style lang="postcss" scoped>
:deep(a) {
  @apply text-primary underline;
}
</style>
