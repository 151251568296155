<template>
  <div class="">
    <MenuItem :click="closeLanguageMenu" icon="long-arrow-left" classes="mt-4" />

    <MenuHeader :text="$t('menu.languageMenu.title')" data-cy="menu-language-title"/>

    <MenuItem :click="() => changeLanguage('de')" data-cy="menu-language-de">
      <div class="lan-item flex h-6 flex-row items-center" style="filter: saturate(0)" :class="{ active: language === 'de' }">
        <img alt="" src="../../assets/flags/de.svg" class="mr-2 h-full object-contain" />
        {{ $t('menu.languageMenu.german') }}
      </div>
    </MenuItem>

    <MenuItem :click="() => changeLanguage('en')" data-cy="menu-language-en">
      <div class="lan-item flex h-6 flex-row items-center" style="filter: saturate(0)" :class="{ active: language === 'en' }">
        <img alt="" src="../../assets/flags/gb.svg" class="mr-2 h-full object-contain" />
        {{ $t('menu.languageMenu.english') }}
      </div>
    </MenuItem>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import MenuHeader from './MenuHeader.vue'
import MenuItem from './MenuItem.vue'

const props = defineProps<{
  closeLanguageMenu: Function,
}>()

const i18n = useI18n()
let language = ref(i18n.locale.value)

const changeLanguage = (languageCode: string) => {
  localStorage.setItem('currentLang', languageCode)
  i18n.locale.value = languageCode
  language.value = languageCode
}
</script>

<style scoped>
.active {
  filter: saturate(1) !important;
  font-weight: bold !important;
}

.lan-item:hover {
  filter: saturate(0.7) !important;
  font-weight: bold !important;
}
</style>
