<template>
  <div
    class="absolute right-2 top-[50%] flex -translate-y-[70%] select-none flex-col gap-5 tablet:bottom-3 tablet:right-1/2 tablet:top-auto tablet:translate-x-[50%] tablet:translate-y-0 tablet:flex-row"
  >
    <Transition v-if="showSwitch" name="slide-up">
      <!-- one floor -->
      <div v-if="floors === 1" class="wrapper" data-cy="viewer-switch">
        <div
          v-tooltip="$t('tooltips.viewerSwitch.groundFloor')"
          :class="activeFloor === 0 && 'active'"
          class="element"
          @click="() => handleOnClick(0)"
        >
          <Icon
            :class="activeFloor === 0 && 'active'"
            :svgIcon="ICON.bungalowGroundfloor"
            class="icon w-10"
            data-action="view-ground"
          />
        </div>
        <div
          v-tooltip="$t('tooltips.viewerSwitch.default')"
          :class="activeFloor === -1 && 'active'"
          class="element"
          @click="() => handleOnClick(-1)"
        >
          <Icon
            :class="activeFloor === -1 && 'active'"
            :svgIcon="ICON.bungalowSelect"
            class="icon"
            data-action="view-house"
          />
        </div>
      </div>

      <!-- two floors -->
      <div v-else-if="floors === 2" class="wrapper" data-cy="viewer-switch">
        <div
          v-tooltip="$t('tooltips.viewerSwitch.groundFloor')"
          :class="activeFloor === 0 && 'active'"
          class="element"
          @click="() => handleOnClick(0)"
        >
          <Icon
            :class="activeFloor === 0 && 'active'"
            :svgIcon="ICON.houseGroundfloor"
            class="icon"
            data-action="view-ground"
          />
        </div>
        <div
          v-tooltip="$t('tooltips.viewerSwitch.firstFloor')"
          :class="activeFloor === 1 && 'active'"
          class="element"
          @click="() => handleOnClick(1)"
        >
          <Icon
            :class="activeFloor === 1 && 'active'"
            :svgIcon="ICON.houseFirstfloor"
            class="icon"
            data-action="view-first"
          />
        </div>
        <div
          v-tooltip="$t('tooltips.viewerSwitch.default')"
          :class="activeFloor === -1 && 'active'"
          class="element"
          @click="() => handleOnClick(-1)"
        >
          <Icon
            :class="activeFloor === -1 && 'active'"
            :svgIcon="ICON.houseSelect"
            class="icon"
            data-action="view-house"
          />
        </div>
      </div>
    </Transition>

    <Transition name="slide-up">
      <div v-if="activeFloor !== -1 && !noRoomstamps" class="flex flex-col gap-5 tablet:flex-row">
        <!-- Roomstamps -->
        <div
          v-tooltip="$t('tooltips.floorStamp')"
          :class="floorInfoNameSquareMetre && 'active'"
          class="element rounded-lg"
          @click=";[(floorInfoNameSquareMetre = !floorInfoNameSquareMetre), handleFloorInfoClick()]"
          data-cy="roomStamp"
        >
          <Icon
            :class="floorInfoNameSquareMetre && 'active'"
            :svgIcon="ICON.floorInfoRoomstamps"
            class="icon"
            data-action="spacestamp"
          />
        </div>

        <!-- Dimensions-->
        <div
          v-tooltip="$t('tooltips.dimensions')"
          class="element rounded-lg"
          @click=";[(floorInfoDimensions = !floorInfoDimensions), handleFloorDimensionsClick()]"
          :class="floorInfoDimensions && 'active'"
        >
          <Icon
            :svgIcon="ICON.floorInfoDimensions"
            class="icon"
            :class="floorInfoDimensions && 'active'"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue'
import {useConfigurationStore} from '../../store/configuration.js'
import {useRoute} from 'vue-router'
import {CameraPosition} from '../../router/index'
import {ICON} from '../../util/icons'
import Icon from '../common/Icon.vue'
import {eventBus} from '../../eventBus'
import {useEventBus} from '@vueuse/core'

const props = defineProps<{
  noRoomstamps?: boolean
}>()

const bus = useEventBus(eventBus)

const configurationStore = useConfigurationStore()
const floors = computed(() => configurationStore.selectedHouseSize?.floors || 1)

const route = useRoute()

const showSwitch = computed(() => route.meta.viewSwitch)
const routeCameraPosition = computed(() => route.meta.camera)
const activeCameraPosition = ref(routeCameraPosition.value)

const floorInfoNameSquareMetre = ref(true)
const floorInfoDimensions = ref(false)

const activeFloor = ref(
  activeCameraPosition.value === CameraPosition.firstFloor
    ? 1
    : activeCameraPosition.value === CameraPosition.groundFloor
    ? 0
    : -1
)

const updateCameraPosition = () => {
  return activeFloor.value === 0
    ? CameraPosition.groundFloor
    : activeFloor.value === 1
    ? CameraPosition.firstFloor
    : route.name === 'Ground Floor' || route.name === 'First Floor' // only use house view on ground floor and first floor
    ? CameraPosition.house
    : routeCameraPosition.value
}

watch(
  () => route.name,
  val => {
    activeCameraPosition.value = routeCameraPosition.value
    if (routeCameraPosition.value === CameraPosition.firstFloor) {
      activeFloor.value = 1
    } else if (routeCameraPosition.value === CameraPosition.groundFloor) {
      activeFloor.value = 0
    } else {
      activeFloor.value = -1
    }
    if (route.name !== 'Home') bus.emit({name: 'toggleViewMode', data: activeFloor.value})
  }
)

watch(
  () => activeFloor.value,
  () => {
    activeCameraPosition.value = updateCameraPosition()
  }
)

watch(
  () => floors.value,
  () => {
    if (activeFloor.value > floors.value - 1) {
      activeFloor.value = floors.value - 1
    }
  }
)

const handleOnClick = (etage: number) => {
  activeFloor.value = etage
  bus.emit({name: 'toggleActiveFloor', data: etage})
  bus.emit({name: 'setCameraPosition', data: updateCameraPosition()})
}

const handleFloorInfoClick = () => {
  bus.emit({
    name: 'toggleFloorInfo',
    data: floorInfoNameSquareMetre.value,
  })
}

const handleFloorDimensionsClick = () => {
  bus.emit({
    name: 'toggleFloorDimensions',
    data: floorInfoDimensions.value,
  })
}

bus.on(async event => {
  if (event.name == 'setActiveFloor') {
    activeFloor.value = event.data
  }
})
</script>

<style scoped>
.wrapper {
  @apply flex flex-col gap-0 tablet:flex-row;
}

.element {
  @apply border border-gray-200 bg-white p-1 shadow-md hover:border-gray-300;
}

.wrapper .element:first-child {
  @apply rounded-t-lg tablet:rounded-t-none tablet:rounded-l-lg;
}

.wrapper .element:last-child {
  @apply rounded-b-lg tablet:rounded-b-none tablet:rounded-r-lg;
}

.wrapper .element:only-child {
  @apply rounded-lg;
}

.icon {
  filter: brightness(0);
  @apply w-6 tablet:w-10;
}

.element.active {
  @apply border-primary;
}

.element.active .icon.active {
  filter: brightness(1);
}

.element:hover {
  cursor: pointer;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>