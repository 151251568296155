import Api from '../service/Api'

export default {
  find(): Promise<any> {
    return Api.get('housesize')
  },
  getByHouseTypeId(houseTypeId: number): Promise<any> {
    return Api.get(`housesize/${houseTypeId}`)
  },
}
