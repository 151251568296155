import {useConfigurationStore} from '../store/configuration'
import {useUserStore} from '../store/user'
import router from '../router'
import pathConstants from '../router/constants'
import {useNavigationStore} from '../store/navigation'

export async function useNewConfigToHome() {
    const configurationStore = useConfigurationStore()
    const userStore = useUserStore()

    // Delete Stores
    useNavigationStore().$reset()
    configurationStore.$reset()
    userStore.$reset()

    // Redirect to Home
    await configurationStore.load().then(async () => {
        await router.push(pathConstants.HOME)
    })
}