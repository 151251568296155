import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../components/menu/Privacy.vue'
import ExistingConfiguration from '../views/ExistingConfiguration.vue'
import {useUserStore} from '../store/user'
import {useConfigurationStore} from '../store/configuration'
import pathConstants from './constants'
import FAQ from '../views/FAQ.vue'
import {useUrlSearchParams} from '@vueuse/core'
import i18n from '../i18n'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../eventBus'
import {status} from '../util/status'

export enum CameraPosition {
  house = 'House',
  houseSize = 'HouseType',
  roofType = 'RoofType',
  groundFloor = 'EG',
  firstFloor = 'OG',
  inside = 'Inside',
  summary = 'Summary',
  screenshots = 'Screenshots',
}

const routes: Array<RouteRecordRaw> = [
  {
    path: pathConstants.HOME,
    name: 'Home',
    component: Home,
  },
  {
    path: `${pathConstants.EXISTING_CONFIGURATION}/:id?`,
    name: 'Existing Configuration',
    component: ExistingConfiguration,
  },
  {
    path: pathConstants.PRIVACY,
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: pathConstants.FAQ,
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: pathConstants.SUCCESS,
    name: 'Configuration Success',
    component: () => import('../views/ConfigurationSuccess.vue'),
    meta: {
      viewer: true,
      camera: CameraPosition.summary,
      viewSwitch: true,
      hideTabNav: true,
      multipleFallbackImages: true,
    },
  },
  {
    path: `${pathConstants.EXPORT_IMAGES}/:id?`,
    name: 'Export images',
    component: () => import('../views/ExportImages.vue'),
    meta: {viewer: true, camera: CameraPosition.screenshots},
  },
  {
    path: pathConstants.Configurator.ROOT,
    name: 'Configuration',
    redirect: pathConstants.Configurator.HOUSE_KIND,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited
  },
  {
    path: pathConstants.Configurator.HOUSE_KIND,
    name: 'House Kind',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HouseType.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.house,
      multipleFallbackImages: true,
    },
  },
  {
    path: pathConstants.Configurator.HOUSE_TYPE,
    name: 'House Type',
    component: () => import('../views/HouseSize.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.houseSize,
      multipleFallbackImages: true,
    },
  },
  {
    path: pathConstants.Configurator.ROOF_TYPE,
    name: 'Roof Type',
    component: () => import('../views/RoofType.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.roofType,
      multipleFallbackImages: true,
    },
  },
  {
    path: pathConstants.Configurator.GROUND_FLOOR,
    name: 'Ground Floor',
    component: () => import('../views/GroundFloor.vue'),
    meta: {configurator: true, viewer: true, camera: CameraPosition.groundFloor},
  },
  {
    path: pathConstants.Configurator.FIRST_FLOOR,
    name: 'First Floor',
    component: () => import('../views/FirstFloor.vue'),
    meta: {configurator: true, viewer: true, camera: CameraPosition.firstFloor},
  },
  {
    path: pathConstants.Configurator.GARAGE_CARPORT,
    name: 'Carport/Garage',
    component: () => import('../views/GarageCarport.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.house,
      multipleFallbackImages: true,
    },
  },
  {
    path: pathConstants.Configurator.ADDITIONAL_SERVICES,
    name: 'Additional Services',
    component: () => import('../views/AdditionalServices.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.house,
      multipleFallbackImages: true,
    },
  },
  {
    path: `${pathConstants.Configurator.CONFIGURATION_OVERVIEW}`,
    name: 'Overview',
    component: () => import('../views/ConfigurationOverview.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.summary,
      viewSwitch: true,
      multipleFallbackImages: true,
    },
  },
  {
    path: pathConstants.Configurator.CONFIGURATION_SAVE,
    name: 'Save',
    component: () => import('../views/ConfigurationSave.vue'),
    meta: {
      configurator: true,
      viewer: true,
      camera: CameraPosition.summary,
      viewSwitch: true,
      multipleFallbackImages: true,
    },
  },
  {
    path: `${pathConstants.OVERVIEW}`,
    name: 'Overview Read Only',
    component: () => import('../views/ConfigurationOverviewReadOnly.vue'),
    meta: {
      viewer: true,
      camera: CameraPosition.summary,
      viewSwitch: true,
      hideTabNav: true,
      multipleFallbackImages: true,
    },
  },
  {
    path: pathConstants.NOT_FOUND,
    name: 'Not Found',
    component: Home,
  },
  {
    path: pathConstants.CATCH_ALL,
    redirect: pathConstants.NOT_FOUND,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()
    const configurationId = configurationStore.configurationId
    const bus = useEventBus(eventBus)

    // ROUTE 404
    if (to.path === pathConstants.NOT_FOUND) {
      bus.emit({
        name: 'toast',
        data: {status: status.warning, message: `${i18n.global.t('toastMessages.404')}`},
      })
    }

    // ROUTE WITH H PARAMETER (jump in link)
    if (to.query.h && !to.query.uid) {
      await configurationStore.loadFromUrlParam(to.query.h.toString())
    }

    // ROUTE WITH UID PARAMETER
    else if (to.query.uid) {
      await configurationStore.load(to.query.uid.toString())
    }

    // EXISTING CONFIGURATION
    const lastVisitedTime = userStore.lastVisitedConfiguratorTime
    const thirtyMinutesAgo = () => new Date(Date.now() - 1000 * 30 * 60)
    if (
      lastVisitedTime &&
      thirtyMinutesAgo() > new Date(lastVisitedTime) &&
      configurationId &&
      !to.query.uid
    ) {
      const lastVisitedDate = new Date(lastVisitedTime).toLocaleString(i18n.global.locale.value, {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })

      bus.emit({
        name: 'dialogPopup',
        data: {
          status: status.info,
          header: `${i18n.global.t('dialogPopup.headerTimeOut')}`,
          message: `${i18n.global.t('dialogPopup.messageTimeOut', {date: lastVisitedDate})}`,
          timeOut: true
        }
      })
    }

    // WITHOUT UID PARAM AND CONFIG IN STORE
    if (
      Object.values(pathConstants.Configurator).includes(to.path) &&
      !to.query.uid &&
      !configurationId &&
      !to.query.h
    ) {
      await configurationStore.load()

      if (to.path !== pathConstants.Configurator.HOUSE_KIND) {
        router.push(pathConstants.Configurator.HOUSE_KIND)
      }
    }

    // check if visited route is part of configurator
    if (from.path !== pathConstants.EXISTING_CONFIGURATION && to.meta.configurator) {
      userStore.setLastVisitedConfigurationPath(to.path, new Date(Date.now()))
    }

    next()
  }
)

router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const configurationStore = useConfigurationStore()
  const configurationId = configurationStore.configurationId

  if (configurationId) {
    const params = useUrlSearchParams('history')
    params.uid = configurationId?.toString()
  }

  if (to.fullPath === '/') {
    // Initial load at home page
    configurationStore.load()
  }
})

export default router
