export interface i18nType {
  [key: string]: string
}

export interface HouseTypeType {
  id: number
  default: boolean
  name: string
  name_i18n: i18nType
  desc_i18n: i18nType[]
  floorsMin: number
  floorsMax: number
  livingSpaceMin: number
  livingSpaceMax: number
  energyStandardMax: string
  price: number
}

export interface HouseSizeType {
  id: number
  name: string
  name_i18n: i18nType
  space: number
  price: number
  housetype_id: number
  default: boolean
  desc_i18n: i18nType[]
  length: number
  width: number
  floors: number
  persons: string
  rooms: string
}

export interface RoofTypeType {
  id: number
  name: string
  default: boolean
  name_i18n: i18nType
  inclination: number
  price: number
  housetype_id: number
  desc_i18n: i18nType[]
}

interface RoomsType {
  name: string
  name_i18n: i18nType
  floorspace: number
  livingspace: number
}

export interface FloorPlanType {
  id: number
  name: string
  name_i18n: i18nType
  desc_i18n: i18nType[]
  url: string
  price: number
  floor: number
  rooms: RoomsType[]
  default: boolean
}

export interface AdditionType {
  id: number
  name: string
  name_i18n: i18nType
  desc_i18n: i18nType[]
  active: string
  category: string
  price: number
}

export interface ConfigurationListElement {
  category?: ConfigurationCategory
  choice?: any
  price?: string | number
  description?: string
  redirect?: string
  icon?: string
  pills?: ConfigurationListPills[]
  desc?: i18nType[]
}

export interface ConfigurationListPills {
  text?: string
  tooltip: string
  icon: string
}

export interface HousePosition {
  x: number
  y: number
  z: number
}

export enum ConfigurationCategory {
  houseType,
  houseSize,
  roofType,
  propertyPostcode,
  propertyCity,
  floorplan,
  groundFloor,
  firstFloor,
  addition,
  carport,
  garage,
}

export enum PropertyChoice {
  no,
  yes,
  inView,
  reserved,
}

export enum MirrorState {
  none = 'None',
  vert = 'Vertical',
  horiz = 'Horizontal',
  both = 'Both'
}

export interface LastVisitedType {
  path: string
  time: Date
}

export interface ImageArrayType {
  north: Uint8Array
  east: Uint8Array
  south: Uint8Array
  west: Uint8Array
  top: Uint8Array
  front: Uint8Array
  eg: Uint8Array
  og: Uint8Array
}
