<template>
  <div
    class="fixed grid h-full w-full grid-cols-ok grid-rows-ok gap-0 overflow-hidden bg-gray-100 grid-areas-mobile tablet:grid-areas-desktop"
  >
    <AppBar :minimal="show3D && ismobile" />
    <NavigationTabs v-if="configurator" class="hidden tablet:flex" />
    <!-- Clear Local Storage and Reset Page -->

    <button
      v-if="DEVELOPMENT"
      :class="ismobile ? 'rounded' : ''"
      class="hover:text-red-600' absolute -top-1 left-14 z-[100] bg-white bg-opacity-50 p-2 text-3xl transition-all"
      @click="resetConfigAndLocalStorage"
    >
      ☢
    </button>
    <UnityViewer v-if="!HIDE_UNITY && deviceInfo === DeviceInfo.desktop" :show="show3D" />
    <UnityFallbackImage v-else-if="show3D && (deviceInfo !== DeviceInfo.desktop || HIDE_UNITY)" />

    <ProgressBar v-if="show3D && ismobile" />
    <router-view />

    <Toast />
  </div>
  <ErrorPopup />
  <DialogPopup />
</template>

<script lang="ts" setup>
// Das ist cool 😍
import AppBar from '@/components/common/AppBar.vue'
import NavigationTabs from '@/components/navigation/NavigationTabs.vue'
import UnityViewer from '@/components/unity/UnityViewer.vue'
import Api from './service/Api'
import {computed, onMounted, ref, reactive} from 'vue'
import {useRoute} from 'vue-router'
import {useConfigurationStore} from './store/configuration'
import {useUserStore} from './store/user'
import {useUnityStore} from './store/unity'
import ErrorPopup from './components/common/ErrorPopup.vue'
import {useWindowResize} from './composables/useWindowResize'
import ProgressBar from './components/common/ProgressBar.vue'
import {useAppStore} from './store/app'
import UnityFallbackImage from './components/unity/UnityFallbackImage.vue'
import useDeviceInfo, {DeviceInfo} from './composables/useDeviceInfo'
import {useNewConfig} from './composables/useNewConfig'
import Toast from './components/common/Toast.vue'
import {useHead} from '@vueuse/head'
import i18n from './i18n'
import DialogPopup from "@/components/common/DialogPopup.vue";

const DEVELOPMENT = import.meta.env.VUE_APP_DEVELOPMENT === 'true' ? true : false
const HIDE_UNITY = import.meta.env.VUE_APP_HIDEUNITY === 'true'
const INTERN_TRAFFIC = import.meta.env.VUE_APP_INTERN_TRAFFIC === 'true' ? true : false
console.log('Intern Connection: ', INTERN_TRAFFIC)

const siteData = reactive({
  title: `${i18n.global.t('pageTitles.main')}`,
  description: `${i18n.global.t('pageMetaDescriptions.main')}`,
})

useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: 'description',
      content: computed(() => siteData.description),
    },
  ],
})

const fullscreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen()
  } else {
    document.body.requestFullscreen()
  }
}

const deviceInfo = useDeviceInfo()

const configurationStore = useConfigurationStore()

const userStore = useUserStore()

const unityStore = useUnityStore()

const appStore = useAppStore()

const route = useRoute()
const show3D = computed(() => route.meta.viewer)
const configurator = computed(() => route.meta.configurator)

const checkVersionAndResetStores = () => {
  const APP_VERSION_MINOR = import.meta.env.VUE_APP_VERSION.slice(0, 3)
  const STORE_APP_VERSION_MINOR = appStore.version.slice(0, 3)

  const UNITY_VERSION_MINOR = import.meta.env.VUE_UNITY_VERSION.slice(0, unityStore.version?.length - 2)
  const STORE_UNITY_VERSION_MINOR = unityStore.version.slice(0, unityStore.version?.length - 2)
}

onMounted(async () => {
  // load all application data
  if (route.name !== 'Export images') checkVersionAndResetStores()

  // reset version every time app is mounted
  appStore.version = import.meta.env.VUE_APP_VERSION
  unityStore.version = import.meta.env.VUE_UNITY_VERSION
})

Api.use400Interceptor()
Api.use401Interceptor()
Api.use403Interceptor()
Api.use404Interceptor()
Api.use500Interceptor()

const ismobile = ref<boolean>(window.innerWidth <= 1024)
useWindowResize((width: number, height: number) => {
  // For mobile browsers the h-screen property does not work as intended
  const doc = document.documentElement
  doc.style.setProperty('--vh', height * 0.01 + 'px')

  if (width <= 1024 && !ismobile.value) ismobile.value = true // MOBILE
  else if (width > 1024 && ismobile.value) ismobile.value = false // DESKTOP
})

// Update Configuration on select a Card
configurationStore.$onAction(({name, store, after}) => {
  const events = [
    'selectHouseType',
    'selectHouseSize',
    'selectRoofType',
    'selectProperty',
    'setPropertyPostalcode',
    'setPropertyCity',
    'selectGroundFloor',
    'selectFirstFloor',
    'toggleMirroringVertical',
    'toggleMirroringHorizontal',
    'selectAdditionalService',
    'unselectAdditionalService',
    'selectGarage',
    'unselectGarage',
    'randomizeConfiguration',
  ]
  const eventsResetMirroring = [
    'selectHouseType',
    'selectHouseSize',
    'selectGroundFloor',
    'selectFirstFloor'
  ]

  // Update Configuration
  if (events.includes(name)) {
    after(() => {
      configurationStore.updateConfiguration()
    })
  }

  // Reset Mirroring
  if (eventsResetMirroring.includes(name)) {
    after(() => {
      configurationStore.resetMirroring()
    })
  }
})

const resetConfigAndLocalStorage = async () => {
  window.localStorage.clear()
  await useNewConfig()
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  background-color: #f1f1f1;
}
</style>
