import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAnglesDown,
  faAnglesLeft,
  faAnglesRight,
  faAnglesUp,
  faArrowUpRightFromSquare,
  faBars,
  faBinoculars,
  faCheck,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCompress,
  faCookie,
  faDownload,
  faExpand,
  faFile,
  faGlobeEurope,
  faHome,
  faLocationDot,
  faLongArrowLeft,
  faLongArrowRight,
  faMagnifyingGlass,
  faMessage,
  faPlus,
  faQuestion,
  faScaleBalanced,
  faShareNodes,
  faShield,
  faSpinner,
  faSwatchbook,
  faTag,
  faThumbsDown,
  faThumbsUp,
  faTriangleExclamation,
  faUser,
  faXmark,
  faScrewdriverWrench,
  faPaste,
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {App} from 'vue'

export function setupIcons(app: App) {
  //FONTAWESOME ICONS
  library.add(
    faTag,
    faLongArrowRight,
    faLongArrowLeft,
    faHome,
    faGlobeEurope,
    faThumbsDown,
    faThumbsUp,
    faLocationDot,
    faMagnifyingGlass,
    faSwatchbook,
    faUser,
    faMessage,
    faShield,
    faFile,
    faScaleBalanced,
    faAnglesDown,
    faAnglesUp,
    faAnglesLeft,
    faAnglesRight,
    faAnglesUp,
    faQuestion,
    faBars,
    faXmark,
    faCheck,
    faExpand,
    faCompress,
    faBinoculars,
    faCircleCheck,
    faCircleExclamation,
    faCircleInfo,
    faTriangleExclamation,
    faDownload,
    faShareNodes,
    faSpinner,
    faAngleDown,
    faAngleRight,
    faAngleLeft,
    faAngleUp,
    faPlus,
    faArrowUpRightFromSquare,
    faCookie,
    faScrewdriverWrench,
    faPaste
  )

  app.component('font-awesome-icon', FontAwesomeIcon)
}
