<template>
  <div
    :style="text ? 'grid-template-columns: auto 1fr' : ''"
    class="grid justify-center text-sm tablet:mt-4 tablet:text-base"
    data-cy="house-consultant"
  >
    <div
      class="mr-3 aspect-square w-12 overflow-hidden rounded-full bg-white ring-2 ring-primary tablet:w-16"
    >
      <img :src="imgSimone" />
    </div>
    <div v-if="text" class="flex flex-col gap-2">
      <div v-for="(string, i) in messages" :key="i" class="speechbubble">
        <p v-html="string"></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, ref, watch} from 'vue'

enum simoneExpression {
  default = 'default',
  confused = 'confused',
}

const props = defineProps<{
  text: string | string[]
  info?: string
  typing?: boolean
  startTime?: number
  expression?: simoneExpression
}>()

const unityEnv = import.meta.env.VUE_UNITY_ENV
const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL

const typing = ref(false)
const messages = ref(Array.isArray(props.text) ? props.text : [props.text])
const imgSimone = ref('')

switch (props.expression) {
  case simoneExpression.confused:
    imgSimone.value = cloudfrontUrl + unityEnv! + '/assets/simone/confused.jpg'
    break

  default:
    imgSimone.value = cloudfrontUrl + unityEnv! + '/assets/simone/default.jpg'
}

const threeDots =
  '<span class="typing"><span class="text-[8px] tablet:text-base">▪</span><span class="text-[8px] tablet:text-base">▪</span><span class="text-[8px] tablet:text-base">▪</span></span>'

onMounted(() => {
  typing.value = props.typing || false
  const textArray: string[] = messages.value

  if (typing.value) {
    messages.value = [threeDots]

    setTimeout(() => {
      messages.value = [textArray[0]]
      textArray.splice(0, 1)
      if (textArray.length > 0) messages.value.push(threeDots)
      if (textArray.length > 0) {
        const IntervalID = setInterval(() => {
          messages.value.pop()
          messages.value = [...messages.value, textArray[0]]
          textArray.splice(0, 1)
          if (textArray.length === 0) clearInterval(IntervalID)
        }, 1000)
      }
    }, props.startTime || 1000)
  }
})

watch(
  () => props.text,
  () => {
    messages.value = Array.isArray(props.text) ? props.text : [props.text]
  }
)
</script>

<style>
.speechbubble {
  @apply w-fit rounded-2xl rounded-tl-none bg-white p-3 text-left shadow-md;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.typing span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin: auto 3px;
}

.typing span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing span:nth-child(3) {
  animation-delay: 0.4s;
}
</style>