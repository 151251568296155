<template>
  <div
    class="group my-2 rounded-lg bg-white text-left transition-all"
    :class="{
      'overflow-y-hidden border shadow hover:shadow-md': dense,
      'shadow-md hover:shadow-lg': !dense,
      'border-none shadow-none hover:border-none hover:shadow-none': flat,
    }"
    ref="element"
    data-cy="accordeon"
  >
    <section
      @click="openDetails"
      class="flex cursor-pointer select-none flex-row items-center p-4"
      :class="dense ? 'p-3' : ''"
      data-cy="accordeon-header"
      :data-accordeon="header"
    >
      <Icon
        class="mr-3 transition-all duration-200"
        :class="showDetails ? 'rotate-90' : 'rotate-0'"
        faIcon="angle-right"
      ></Icon>
      <div class="flex w-full cursor-pointer items-center font-bold" :class="{'not-dense': !dense}">
        <Icon v-if="props.icon" :svgIcon="props.icon" :alt="icon" class="mr-1 w-6" />
        <div v-if="header" class="flex w-full justify-between text-sm tablet:text-base">
          <h3 class="" v-if="header" v-html="header"></h3>
          <h3 v-if="headerRight">{{ headerRight }}</h3>
        </div>
        <slot class="col-span-2 pt-3" name="headerSlot"></slot>
      </div>
    </section>
    <Transition>
      <section
        @click.stop
        v-if="showDetails"
        class="mx-3 overflow-hidden rounded-t-lg"
        data-cy="accordeon-body"
      >
        <slot name="contentSlot"></slot>
      </section>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {ICON} from '../../util/icons'
import Icon from './Icon.vue'

const showDetails = ref(false)
const element = ref<HTMLElement>()

const props = defineProps<{
  header?: string
  headerRight?: string
  expanded?: boolean
  dense?: boolean
  icon?: ICON
  flat?: boolean
}>()

if (props.expanded) showDetails.value = true

const openDetails = () => {
  if (showDetails.value === false) {
    element.value?.scrollIntoView({behavior: 'smooth'})
  }

  showDetails.value = !showDetails.value
}
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: all 0.2s;
  max-height: 230px;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  max-height: 0;
}

.not-dense {
  @apply grid grid-cols-2 text-sm  tablet:text-base;
  grid-template-columns: 1fr auto;
}
</style>
