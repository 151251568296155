<template>
  <div
    class="relative overflow-hidden row-start-appbar row-end-viewer col-start-viewer col-end-viewer tablet:grid-in-viewer"
  >
    <Transition name="fade">
      <img
        :key="imgHouseModel"
        :src="imgHouseModel"
        alt=""
        class="h-full w-full object-cover"
        :class="{
          '-scale-x-100': mirrorVert && (viewMode === CameraPosition.groundFloor || viewMode === CameraPosition.firstFloor),
          '-scale-y-100': mirrorHoriz && (viewMode === CameraPosition.groundFloor || viewMode === CameraPosition.firstFloor)
        }"
        data-cy="unityFallback"
      />
    </Transition>

    <div
      v-if="multipleFallbackImages && activeFloor === -1"
      class="absolute bottom-3 left-[50%] flex -translate-x-[50%] gap-2"
    >
      <Button class="h-8 w-12" icon-left="arrow-left-long" @click="imageBack" />
      <span class="self-center font-semibold">{{ cnt + 1 + ' ' + $t('common.of4') }}</span>
      <Button class="h-8 w-12" icon-left="arrow-right-long" @click="imageNext" />
    </div>

    <ViewerSwitch noRoomstamps />
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import {useConfigurationStore} from '../../store/configuration'
import {useRoute} from 'vue-router'
import ViewerSwitch from './ViewerSwitch.vue'
import i18n from '../../i18n'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import Button from '../common/Button.vue'
import {CameraPosition} from '../../router'

const bus = useEventBus(eventBus)

const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL
const unityEnv = import.meta.env.VUE_UNITY_ENV
const unityVersion = import.meta.env.VUE_UNITY_VERSION

const configurationStore = useConfigurationStore()

const route = useRoute()
const cameraPosition = computed(() => route.meta.camera)
const showSwitch = computed(() => route.meta.viewSwitch)
const multipleFallbackImages = computed(() => route.meta.multipleFallbackImages)

const imgHouseModel = ref('')
const viewMode = ref('')
const firstFloor = ref('')
const activeFloor = ref(-1)
const showFloorInfo = ref(true)
const cnt = ref(0)
const image = ref('')
const floorPlanImage = ref(false)
const mirrorVert = ref(false)
const mirrorHoriz = ref(false)

onMounted(async () => {
  updateURL()
})

watch(
  () => i18n.global.locale.value,
  val => {
    updateURL()
  }
)

watch(
    [() => configurationStore.floorPlanMirroredVert || false, () => configurationStore.floorPlanMirroredHoriz || false],
    ([mirrorVertValue, mirrorHorizValue]) => {
      mirrorVert.value = mirrorVertValue
      mirrorHoriz.value = mirrorHorizValue
    }
)

bus.on(async event => {
  switch (event.name) {
    case 'toggleFloorInfo':
      showFloorInfo.value = event.data
      updateURL()
      break
    case 'toggleActiveFloor':
      activeFloor.value = event.data
      updateURL()
      break
    case 'toggleViewMode':
      activeFloor.value = event.data
      updateURL()
      break
    case 'toggleMirrorVert':
      mirrorVert.value = event.data
      break
    case 'toggleMirrorHoriz':
      mirrorHoriz.value = event.data
  }
})
configurationStore.$onAction(({name, store, after}) => {
  const events = [
    'selectHouseType',
    'selectHouseSize',
    'selectRoofType',
    'selectGroundFloor',
    'selectFirstFloor',
    'randomizeConfiguration',
  ]
  if (events.includes(name)) {
    after(() => {
      updateURL()
    })
  }
})

const updateURL = () => {
  // Floorplans
  if (
    cameraPosition.value === CameraPosition.groundFloor ||
    cameraPosition.value === CameraPosition.firstFloor
  ) {
    viewMode.value = cameraPosition.value
    floorPlanImage.value = true
  } else {
    // ViewerSwitch
    if (showSwitch.value === true) {
      switch (activeFloor.value) {
        case 0:
          viewMode.value = CameraPosition.groundFloor
          floorPlanImage.value = true
          break
        case 1:
          viewMode.value = CameraPosition.firstFloor
          floorPlanImage.value = true
          break
        case -1:
          viewMode.value = CameraPosition.house
          floorPlanImage.value = false
          break
      }
    } else {
      if (cameraPosition.value === CameraPosition.roofType) {
        viewMode.value = cameraPosition.value
      } else {
        viewMode.value = CameraPosition.house
      }
      floorPlanImage.value = false
    }
  }
  if (floorPlanImage.value === true) {
    image.value = '/image.jpg'
  } else {
    image.value = '/image_' + cnt.value + '.jpg'
  }

  // First Floor
  if (configurationStore.selectedHouseSize?.floors === 1) {
    firstFloor.value = configurationStore.selectedHouseSize?.name + '_Undefined'
  } else {
    firstFloor.value = configurationStore.selectedFirstFloor?.name
  }

  // URL
  let URLString = ''
  // bei viewModel 'House' und 'RoofType'
  if (viewMode.value === CameraPosition.house || viewMode.value === CameraPosition.roofType) {
    URLString =
      viewMode.value +
      '/' +
      configurationStore.selectedHouseType?.name +
      '/' +
      configurationStore.selectedHouseSize?.name +
      '/' +
      configurationStore.selectedRoofType?.name.split('_').pop() +
      '/' +
      configurationStore.selectedGroundFloor?.name +
      '/' +
      firstFloor.value

    // bei EG / OG
  } else {
    URLString =
      viewMode.value +
      '/' +
      configurationStore.selectedHouseType?.name +
      '/' +
      configurationStore.selectedHouseSize?.name +
      '/'

    if (viewMode.value === CameraPosition.groundFloor) {
      URLString += configurationStore.selectedGroundFloor?.name
    } else if (viewMode.value === CameraPosition.firstFloor) {
      URLString += configurationStore.selectedFirstFloor?.name
    }
  }

  imgHouseModel.value = cloudfrontUrl + unityEnv + '/unity/' + unityVersion + '/images/' + URLString + image.value
}

const imageBack = () => {
  if (cnt.value === 0) cnt.value = 3
  else if (cnt.value > 0) cnt.value--
  updateURL()
}

const imageNext = () => {
  if (cnt.value === 3) cnt.value = 0
  else if (cnt.value < 3) cnt.value++
  updateURL()
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  position: absolute;
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  position: relative;
  opacity: 1;
}
</style>
