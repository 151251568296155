<template>
  <div
    v-if="showDialogue"
    class="fixed inset-0 z-30 flex bg-black bg-opacity-50 tablet:hidden"
  ></div>
  <transition name="dialogue">
    <div
      v-if="showDialogue"
      :class="{'z-40': stackOnTop}"
      class="fixed inset-0 z-30 flex tablet:absolute tablet:row-start-content tablet:col-start-content"
      data-cy="dialogue"
      @click="close"
    >
      <div
        class="fixed bottom-0 flex max-h-[80vh] w-full flex-col overflow-hidden rounded-t-xl bg-white py-4 text-left tablet:relative tablet:h-full tablet:max-h-fit tablet:rounded-none tablet:pb-3 tablet:pt-0"
        @click.stop
      >
        <Icon
          class="ml-auto mt-6 hidden w-fit px-12 text-xl text-black transition hover:rotate-180 hover:cursor-pointer tablet:block"
          data-cy="dialogue-close-icon"
          faIcon="xmark"
          @click="close"
        />

        <div v-if="header" class="mb-4 px-5 text-base font-bold tablet:px-12 tablet:text-xl">
          {{ header }}
        </div>
        <div
          class="overflow-y-auto px-5 tablet:flex tablet:h-full tablet:flex-col tablet:gap-2 tablet:self-center tablet:px-12"
        >
          <slot name="content"></slot>
        </div>
        <div class="mt-4 flex justify-end px-5 tablet:px-12">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import Icon from '../common/Icon.vue'
import useDeviceInfo from '../../composables/useDeviceInfo'

const props = defineProps<{
  showDialogue: boolean
  preventBgClick?: boolean
  header?: string
  stackOnTop?: boolean
}>()

const deviceInfo = useDeviceInfo()

const emit = defineEmits(['close'])

const close = () => {
  if (!props.preventBgClick) {
    emit('close')
  }
}
</script>

<style>
.dialogue-enter-active {
  transition: all 0.3s ease-out;
}

.dialogue-leave-active {
  transition: all 0.3s ease-in;
}

.dialogue-enter-from,
.dialogue-leave-to {
  opacity: 0;
}
</style>
