<template>
  <div class="z-[42] bg-white text-left">
    <LanguageMenu v-if="showLanguageMenu" :closeLanguageMenu="closeLanguageMenu" />

    <!-- Logo -->
    <img
      v-if="show3D && ismobile"
      :src="cloudfrontUrl + unityEnv + '/assets/logo_green.svg'"
      alt="logo"
      class="logo absolute right-4 top-2 h-7 cursor-pointer justify-self-center desktop:h-8"
      @click="logoClick"
    />

    <!-- Menu -->
    <div v-if="showMenu">
      <MenuHeader :text="$t('menu.configurator')" />
      <MenuItem
        :click="() => redirectAndClose(lastVisitedConfiguratorPath)"
        :text="$t('menu.existingConfiguration')"
        data-cy="menu-existing-configuration"
        data-action="existing_configuration"
        icon="home"
      />
      <MenuItem
        :click="() => newConfigAndClose()"
        :text="$t('menu.newconfiguration')"
        data-cy="menu-new-configuration"
        data-action="new_configuration"
        icon="plus"
      />
      <!-- <MenuItem icon="swatchbook" :text="$t('menu.tinder')" /> -->

      <!-- Settings -->
      <MenuHeader :text="$t('menu.settings')" />
      <!-- <MenuItem icon="user" :text="$t('menu.myprofile')" /> -->
      <MenuItem
        :click="openLanguageMenu"
        :text="$t('menu.language')"
        data-cy="menu-language"
        data-action="language"
        icon="globe-europe"
      />
      <!-- Help -->
      <MenuHeader :text="$t('menu.help')" />
      <MenuItem
        :click="() => redirectAndClose(pathConstants.FAQ)"
        :text="$t('menu.faq')"
        data-cy="menu-faq"
        data-action="faq"
        icon="question"
      />
      <!-- Legal -->
      <MenuHeader :text="$t('menu.legal')" />
      <!-- <MenuItem icon="message" :text="$t('menu.contact')" /> -->
      <MenuItem
        :click="() => redirectAndClose(pathConstants.PRIVACY)"
        :text="$t('menu.privacy')"
        data-cy="menu-privacy"
        data-action="privacy_offcanvas"
        icon="shield"
      />
      <!-- <MenuItem icon="file" :text="$t('menu.termsofuse')" /> -->
      <MenuItem
        :text="$t('menu.impressum')"
        a="https://www.zieglerhaus.de/impressum/"
        data-cy="menu-impressum"
        data-action="imprint"
        icon="scale-balanced"
      />
      <MenuItem
        :click="openCookiePanel"
        :text="$t('menu.cookies')"
        data-cy="menu-cookies"
        data-action="cookiesettings"
        icon="cookie"
      />

      <MenuItem
        v-if="DEVELOPMENT"
        @click="devMode = !devMode"
        :text="devMode ? 'DEV-MODE ON' : 'DEV-MODE OFF'"
        classes="top-[75%] absolute w-full"
        icon="screwdriver-wrench"
        :class="devMode ? 'bg-primary' : 'bg-gray-200'"
      ></MenuItem>
      <p v-if="devMode" class="pointer-events-none absolute left-2 top-[85%] z-[100]">
        App-Version: {{ storeVersion }} <br />
        Unity-Version: {{ unityVersion }} <br />
      </p>

      <MenuItem
        v-if="!externTraffic"
        @click="generateLink"
        :text="$t('menu.linkMarketing')"
        icon="paste"
        class="mt-20"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from 'vue'
import router from '../../router'
import pathConstants from '../../router/constants'
import LanguageMenu from './LanguageMenu.vue'
import MenuHeader from './MenuHeader.vue'
import MenuItem from './MenuItem.vue'
import {useUserStore} from '../../store/user'
import {useConfigurationStore} from '../../store/configuration'
import {useNewConfig} from '../../composables/useNewConfig'
import {useRoute} from 'vue-router'
import {useWindowResize} from '../../composables/useWindowResize'
import {useAppStore} from '../../store/app'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import {status} from '../../util/status'
import i18n from '../../i18n'
import {useClipboard} from '@vueuse/core'
import {useNewConfigToHome} from "../../composables/useNewConfigToHome"

const props = defineProps<{
  // eslint-disable-next-line @typescript-eslint/ban-types
  toggleMenu: Function
}>()

const appStore = useAppStore()
const DEVELOPMENT = import.meta.env.VUE_APP_DEVELOPMENT === 'true' ? true : false
const devMode = computed({
  get: () => appStore.devMode,
  set: value => appStore.setDevMode(value),
})
const unityEnv = import.meta.env.VUE_UNITY_ENV
const unityVersion = import.meta.env.VUE_UNITY_VERSION
const storeVersion = import.meta.env.VUE_APP_VERSION
const cloudfrontUrl = import.meta.env.VUE_APP_CLOUDFRONT_URL
const externTraffic = import.meta.env.VUE_APP_EXTERN_TRAFFIC === 'true' ? true : false

const route = useRoute()
const show3D = computed(() => route.meta.viewer)

const ismobile = ref<boolean>(window.innerWidth <= 1024)
useWindowResize((width: number, height: number) => {
  // For mobile browsers the h-screen property does not work as intended
  const doc = document.documentElement
  doc.style.setProperty('--vh', height * 0.01 + 'px')

  if (width <= 1024 && !ismobile.value) ismobile.value = true // MOBILE
  else if (width > 1024 && ismobile.value) ismobile.value = false // DESKTOP
})

const userStore = useUserStore()
const configurationStore = useConfigurationStore()

const showMenu = ref(true)
const showLanguageMenu = ref(false)

const lastVisitedConfiguratorPath = computed(() => userStore.lastVisitedConfiguratorPath)
const configurationId = computed(() => configurationStore.configurationId)

const logoClick = () => {
  if (router.currentRoute.value.path !== pathConstants.HOME) {
    // at success or overview route directly to home
    if (router.currentRoute.value.path === pathConstants.SUCCESS ||
        router.currentRoute.value.path === pathConstants.OVERVIEW) {
      useNewConfigToHome()

      // show popup while in configuration
    } else {
      const bus = useEventBus(eventBus)

      bus.emit({
        name: 'dialogPopup',
        data: {
          status: status.warning,
          header: `${i18n.global.t('dialogPopup.headerProcess')}`,
          message: `${i18n.global.t('dialogPopup.messageProcess')}`,
          process: true
        }
      })
    }
    props.toggleMenu()
  }
}

const redirectAndClose = async (path: string) => {
  await router.push(path)
  props.toggleMenu()
}

const CF = window.CookieFirst
const openCookiePanel = () => {
  CF.openPanel()
}

const newConfigAndClose = async () => {
  await useNewConfig()
  props.toggleMenu()
}

const openLanguageMenu = () => {
  showLanguageMenu.value = true
  showMenu.value = false
}
const closeLanguageMenu = () => {
  showLanguageMenu.value = false
  showMenu.value = true
}

const generateLink = async () => {
  const {copy} = useClipboard()
  const bus = useEventBus(eventBus)

  const url = 'mein.zieglerhaus.de/configuration/house-type'
  const param = '?h='
  const separator = '_'

  let error = false

  if (
    configurationStore.selectedHouseType?.id &&
    configurationStore.selectedHouseSize?.id &&
    configurationStore.selectedRoofType?.id &&
    configurationStore.selectedGroundFloor?.id
  ) {
    let link =
      url +
      param +
      configurationStore.selectedHouseType.id +
      separator +
      configurationStore.selectedHouseSize.id +
      separator +
      configurationStore.selectedRoofType.id +
      separator +
      configurationStore.selectedGroundFloor.id

    if (configurationStore.selectedFirstFloor) {
      link += separator + configurationStore.selectedFirstFloor.id
    }

    try {
      await copy(link)

      bus.emit({
        name: 'toast',
        data: {
          status: status.success,
          message: `${i18n.global.t('toastMessages.copyClipboardSuccess')}`,
          subMessage: link,
        },
      })
    } catch (e) {
      error = true
    }
  } else {
    error = true
  }

  if (error) {
    bus.emit({
      name: 'toast',
      data: {
        status: status.error,
        message: `${i18n.global.t('toastMessages.copyClipboardError')}`,
        subMessage: `${i18n.global.t('toastMessages.copyClipboardErrorSub')}`,
      },
    })
  }
}
</script>
