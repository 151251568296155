<template>
  <div v-if="showPopup" class="fixed inset-0 z-50 flex bg-black bg-opacity-50" @click="() => showPopup = !showPopup">
    <div
      class="fixed bottom-0 m-auto flex max-h-[80vh] w-full flex-col overflow-hidden rounded-t-xl bg-white px-3 py-6 text-left tablet:relative tablet:max-h-[85vh] tablet:w-[526px] tablet:rounded-xl tablet:px-6"
      @click.stop
    >
      <div class="flex">
        <Icon
            v-if="statusValue === status.info"
            class="pr-3 text-2xl text-status-info tablet:text-4xl tablet:pr-6"
            faIcon="circle-info"
        />
        <Icon
            v-else-if="statusValue === status.warning"
            class="pr-3 text-2xl text-status-warning tablet:text-4xl tablet:pr-6"
            faIcon="triangle-exclamation"
        />
        <div class="flex-grow">
          <div v-if="header" class="text-md mb-2 font-bold tablet:text-xl">{{ header }}</div>
          <div class="mt-4 overflow-y-auto text-sm tablet:mt-0">
            {{ message }}
          </div>
          <div class="mt-8 tablet:mt-4 flex gap-3 justify-end">
            <Button :msg="msgButtonSec" @click="handleSecondary" />
            <Button :msg="msgButtonPri" primary @click="handlePrimary" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import Icon from '../common/Icon.vue'
import Button from "@/components/common/Button.vue"
import router from '../../router'
import pathConstants from '../../router/constants'
import {useNewConfig} from "../../composables/useNewConfig"
import {status} from '../../util/status'
import i18n from "../../i18n";

const showPopup = ref(false)
const statusValue = ref("")
const header = ref('')
const message = ref('')
const msgButtonSec = ref('')
const msgButtonPri = ref('')
const timeOut = ref(false)
const process = ref(false)

const bus = useEventBus(eventBus)
bus.on(event => {
  if (event.name === 'dialogPopup') {
    showPopup.value = true
    statusValue.value = event.data.status
    header.value = event.data.header
    message.value = event.data.message
    timeOut.value = event.data.timeOut
    process.value = event.data.process
    if (timeOut.value) {
      msgButtonSec.value = `${i18n.global.t('common.restart')}`
      msgButtonPri.value = `${i18n.global.t('common.continue')}`
    } else if (process.value) {
      msgButtonSec.value = `${i18n.global.t('common.yes')}`
      msgButtonPri.value = `${i18n.global.t('common.no')}`
    }
  }
})

const handleSecondary = () => {
  if (timeOut.value) {
    useNewConfig()
  } else if (process.value) {
    if (router.currentRoute.value.path !== pathConstants.HOME) {
      router.push(pathConstants.HOME)
    }
  }
  showPopup.value = !showPopup.value
}

const handlePrimary = () => {
  // Close Popup and continue configuration
  showPopup.value = !showPopup.value
}

</script>

<style scoped></style>