import i18n from '../i18n'

var priceFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

export function formatPrice(price: number) {
  // we do not want that zero prices are displayed as '0,00 €' - so we simply fall back to '0 €'
  if (price === 0) {
    return i18n.global.t('navigation.included')
  }

  return priceFormat.format(price)
}

var numberFormat = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

export function formatNumber(number: number) {
  return numberFormat.format(number)
}
