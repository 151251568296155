<template>
  <!-- LOADING SCREEN -->
  <LoadingScreen v-if="loading" v-show="show" :percent="loadingPercent" />
  <!-- UNITY -->
  <div
    v-show="show"
    :class="[
      fullScreen
        ? 'absolute inset-0 !z-[49]'
        : 'relative row-start-appbar row-end-viewer col-start-viewer col-end-viewer tablet:grid-in-viewer',
      hideTabNav && 'tablet:row-start-navbar',
    ]"
    class="unity_viewer z-20 overflow-hidden"
    data-cy="unity-viewer"
  >
    <!-- UNITY CANVAS -->
    <UnityVue
      :unity="Unity"
      class="h-full w-full !cursor-move"
      data-action="unityviewer"
      @mousedown="_360ClickHandler"
      @touchstart="_360ClickHandler"
      tabindex="1"
    />
    <ViewerSwitch />
    <!-- 360 ICON -->
    <Transition name="_360">
      <div
        v-if="show360Icon"
        class="pointer-events-none absolute inset-0 flex items-center justify-center"
      >
        <div class="rounded-full bg-white bg-opacity-60 p-2 tablet:p-4">
          <Icon :svgIcon="ICON._360" class="w-14 opacity-80 tablet:w-20" />
        </div>
      </div>
    </Transition>

    <!-- FULLSCREEN ICON -->
    <div class="">
      <div
        v-tooltip="
          fullScreen ? $t('tooltips.toggleFullscreenOff') : $t('tooltips.toggleFullscreenOn')
        "
        class="absolute bottom-0 right-0 cursor-pointer p-2 pl-4 pt-4"
        data-cy="toggle-fullscreen"
        @click="() => (fullScreen = !fullScreen)"
      >
        <Icon
          :faIcon="fullScreen ? 'compress' : 'expand'"
          class="align-middle text-xl transition-all hover:text-gray-600"
          data-action="fullscreen"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import i18n from '../../i18n'
import UnityWebgl from 'unity-webgl'
import UnityVue from 'unity-webgl/vue'
import {computed, ref, watch} from 'vue'
import {useConfigurationStore} from '../../store/configuration'
import {useRoute} from 'vue-router'
import {CameraPosition} from '../../router'
import {useEventBus, useEventListener} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import ViewerSwitch from './ViewerSwitch.vue'
import {useUnityStore} from '../../store/unity'
import Icon from '../common/Icon.vue'
import {ICON} from '../../util/icons'
import useDeviceInfo, {DeviceInfo} from '../../composables/useDeviceInfo'
import LoadingScreen from './LoadingScreen.vue'
import {useAppStore} from '../../store/app'
import * as Sentry from '@sentry/vue'
import {MirrorState} from '../../store/configurationTypes'

interface UpdateJsonType {
  houseLine: string
  houseType: string
  roof: string
  viewMode: unknown
  floorplanEg: string
  floorplanOg: string
  deviceInfo: DeviceInfo
  language: any
  showFloorInfo: boolean
  showDimensionLines: boolean
  debugMode: boolean
  mirrorState: string
}

const bus = useEventBus(eventBus)

const props = defineProps({
  show: Boolean,
})

const route = useRoute()
const cameraPosition = computed(() => route.meta.camera)
const hideTabNav = computed(() => route.meta.hideTabNav)
const unityCamera = ref(cameraPosition.value)

const appStore = useAppStore()
const devMode = computed(() => appStore.devMode)
const loading = ref(true)
const fullScreen = ref(false)
const showFloorInfo = ref(true)
const showErrorPopup = ref(false)
const showDimensions = ref(false)

const configurationStore = useConfigurationStore()

const floorPlanMirroredVert = computed(() => configurationStore.floorPlanMirroredVert)
const floorPlanMirroredHoriz = computed(() => configurationStore.floorPlanMirroredHoriz)

const storeReady = computed(() => configurationStore.storeReady)
const selectedHousetype = computed(() => configurationStore.selectedHouseType)
const selectedHousesize = computed(() => configurationStore.selectedHouseSize)
const selectedRooftype = computed(() => configurationStore.selectedRoofType)
const selectedGroundFloor = computed(() => configurationStore.selectedGroundFloor)
const selectedFirstFloor = computed(() => configurationStore.selectedFirstFloor)

const unityStore = useUnityStore()

const unityEnv = import.meta.env.VUE_UNITY_ENV
const unityVersion = import.meta.env.VUE_UNITY_VERSION
const unityPath = import.meta.env.VUE_APP_CLOUDFRONT_URL + unityEnv + '/unity/' + unityVersion + '/3DScene'

const Unity = new UnityWebgl('#unity_container', {
  loaderUrl: unityPath + '.loader.js',
  dataUrl: unityPath + '.data.br',
  frameworkUrl: unityPath + '.framework.js.br',
  codeUrl: unityPath + '.wasm.br',
  productVersion: unityEnv + '/unity/' + unityVersion
})

Unity.on('created', () => {
  useEventListener(window, 'unityEvent', (e: Event) => unityEventHandler(e))
})

// on progress for loadingscreen
const loadingPercent = ref(0)
Unity.on('progress', (progress: number) => {
  loadingPercent.value = Math.floor(progress * 100)
})

// init fullscreen by horizontal view
useEventListener(window, 'orientationchange', (e: Event) => {
  fullScreen.value = Math.abs(e.target.orientation) === 90
})

const getUpdateJson = () => {
  let mirrorState = MirrorState.none
  if (floorPlanMirroredVert.value && floorPlanMirroredHoriz.value) {
    mirrorState = MirrorState.both
  } else if (floorPlanMirroredVert.value) {
    mirrorState = MirrorState.vert
  } else if (floorPlanMirroredHoriz.value) {
    mirrorState = MirrorState.horiz
  }

  return {
    houseLine: selectedHousetype.value?.name || '',
    houseType: selectedHousesize.value?.name || '',
    roof: selectedRooftype.value?.name.split('_').pop() || '',
    viewMode: unityCamera.value || CameraPosition.house,
    floorplanEg: selectedGroundFloor.value ? selectedGroundFloor.value?.name : '',
    floorplanOg: selectedFirstFloor.value ? selectedFirstFloor.value?.name : '',
    deviceInfo: useDeviceInfo(),
    language: i18n.global.locale.value || 'de',
    showFloorInfo: showFloorInfo.value,
    showDimensionLines: showDimensions.value,
    debugMode: devMode.value,
    mirrorState: mirrorState,
    unityVersion: unityEnv + '/unity/' + unityVersion
  }
}

const isJsonValid = (updateJson: UpdateJsonType) => {
  if (updateJson.floorplanEg === '') return false
  else if (selectedHousesize.value?.floors === 1 && updateJson.floorplanOg !== '') return false
  else if (selectedHousesize.value?.floors === 2 && updateJson.floorplanOg === '') return false
  else if (!updateJson.floorplanEg.includes(updateJson.houseType)) return false
  else if (updateJson.floorplanOg !== '' && !updateJson.floorplanOg.includes(updateJson.houseType))
    return false
  return true
}

const sendInitSceneIfReady = () => {
  if (unityStore.unityReady && configurationStore.storeReady) {
    sendToUnity('InitScene', getUpdateJson())
    unityStore.setUnityInitialized()
  }
}

const sendUpdateScene = () => {
  if (unityStore.unityInitialized) {
    sendToUnity('UpdateScene', getUpdateJson())
  }
}

// handle unity events
const unityEventHandler = (event: any) => {
  const eventType = event.detail.name

  if (eventType === 'UnityInitialized') {
    unityStore.setUnityReady()
    loading.value = false
  }

  if (eventType === 'UnityError') {
    const error = event.detail.error
    bus.emit({name: 'apiError'})
    console.log('Caught Unity Error', error)
    Sentry.captureException(error, {
      contexts: {
        json: getUpdateJson(),
      },
    })
  }

  if (eventType === 'ImageExport') {
    const images = event.detail.images
    window._images = images

    const readyDiv = document.createElement('div')
    readyDiv.setAttribute('id', 'ready')
    document.querySelector('body')?.appendChild(readyDiv)
  }

  if (eventType === 'HousePosition') {
    const house_position = event.detail.message
    configurationStore.setHousePosition(house_position)
  }
}

const sendToUnity = (func: string, updateJson: UpdateJsonType) => {
  try {
    // only send to unity if json is valid
    if (isJsonValid(updateJson)) {
      Unity.send(`Receiver`, func, updateJson)

      // log json if devMode on
      if (devMode.value) {
        console.log(updateJson)
      }
    }
  } catch (error) {
    console.log('Caught Unity Error', error)
    Sentry.captureException(error, {
      contexts: {
        json: getUpdateJson(),
      },
    })
  }
}

// handle frontend events
bus.on(async event => {
  switch (event.name) {
    case 'exportImages':
      console.log('Send export images to unity')
      sendToUnity('ExportImages', getUpdateJson())
      break
    case 'setCameraPosition':
      unityCamera.value = event.data
      sendUpdateScene()
      break
    case 'toggleFloorInfo':
      showFloorInfo.value = !showFloorInfo.value
      sendUpdateScene()
      break
    case 'toggleFloorDimensions':
      showDimensions.value = !showDimensions.value
      sendUpdateScene()
  }
})

// watcher on unityReady and storeReady for init scene
// -----------------------------------------------------
watch(
  () => unityStore.unityReady,
  val => {
    sendInitSceneIfReady()
  }
)
watch(
  () => configurationStore.storeReady,
  val => {
    sendInitSceneIfReady()
  }
)
// -----------------------------------------------------

// watcher on mirroring value vertical and horizontal
// -----------------------------------------------------
watch(
  () => configurationStore.floorPlanMirroredVert,
  val => {
    sendUpdateScene()
  }
)
watch(
  () => configurationStore.floorPlanMirroredHoriz,
  val => {
    sendUpdateScene()
  }
)
// -----------------------------------------------------

watch(
  () => cameraPosition.value,
  val => {
    unityCamera.value = cameraPosition.value
    sendUpdateScene()
  }
)

watch(
  () => devMode.value,
  val => {
    sendUpdateScene()
  }
)

watch(
  () => i18n.global.locale.value,
  val => {
    sendUpdateScene()
  }
)

configurationStore.$onAction(({name, store, after}) => {
  const events = [
    'selectHouseType',
    'selectHouseSize',
    'selectRoofType',
    'selectProperty',
    'selectGroundFloor',
    'selectFirstFloor',
    'randomizeConfiguration',
  ]

  if (events.includes(name)) {
    after(() => {
      sendUpdateScene()
    })
  }
})

// 360 Icon
const show360Icon = computed(() => unityStore.show360)
const _360ClickHandler = () => {
  unityStore.setShow360()
}

const closeErrorPopup = async () => {
  window.localStorage.clear()
  location.reload()
  showErrorPopup.value = false
}
</script>

<style scoped>
._360-enter-active,
._360-leave-active {
  transition: opacity 0.5s ease;
}

._360-enter-from,
._360-leave-to {
  opacity: 0;
}
</style>