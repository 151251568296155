<template>
  <Transition name="slide-down">
    <div v-if="showToast" class="absolute top-14 z-[100] mx-2 flex justify-self-center bg-white">
      <div
        class="mr-4 w-3"
        :class="[
          statusToast === status.warning ? 'bg-toast-warning' : '',
          statusToast === status.error ? 'bg-toast-error' : '',
          statusToast === status.success ? 'bg-toast-success' : '',
          statusToast === status.info ? 'bg-toast-info' : '',
        ]"
      />

      <Icon
        v-if="statusToast === status.success"
        class="self-center text-3xl text-status-success tablet:text-4xl"
        faIcon="circle-check"
      />
      <Icon
        v-else-if="statusToast === status.info"
        class="self-center text-3xl text-status-info tablet:text-4xl"
        faIcon="circle-info"
      />
      <Icon
        v-else-if="statusToast === status.warning"
        class="self-center text-3xl text-status-warning tablet:text-4xl"
        faIcon="triangle-exclamation"
      />
      <Icon
        v-else-if="statusToast === status.error"
        class="self-center text-3xl text-status-error tablet:text-4xl"
        faIcon="circle-exclamation"
      />

      <div
        class="flex flex-col text-sm font-bold tablet:text-base"
        :class="toastSubMessage ? 'px-6 py-4' : 'p-6'"
      >
        <span>{{ toastMessage }}</span>
        <span v-if="toastSubMessage">{{ toastSubMessage }}</span>
      </div>

      <Icon
        class="self-center pr-6 text-2xl hover:cursor-pointer tablet:text-3xl"
        faIcon="xmark"
        @click="showToast = !showToast"
      />
    </div>
  </Transition>
</template>
<script lang="ts" setup>
import Icon from '../common/Icon.vue'
import {ref} from 'vue'
import {useEventBus} from '@vueuse/core'
import {eventBus} from '../../eventBus'
import {status} from '../../util/status'

const showToast = ref(false)
const statusToast = ref('')
const toastMessage = ref('')
const toastSubMessage = ref('')

const bus = useEventBus(eventBus)
bus.on(event => {
  if (event.name === 'toast') {
    showToast.value = true
    statusToast.value = event.data.status
    toastMessage.value = event.data.message
    toastSubMessage.value = event.data.subMessage

    setTimeout(() => {
      showToast.value = false
    }, 6000)
  }
})
</script>
<style>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.25s ease-out;
}

.slide-down-leave-to,
.slide-down-enter-from {
  opacity: 0;
  transform: translateY(-60px);
}
</style>
