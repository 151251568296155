import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import i18n from './i18n'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {setupIcons} from './fontawesome'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import * as Sentry from '@sentry/vue'
import {createHead} from '@vueuse/head'

const DEVELOPMENT = import.meta.env.VUE_APP_DEVELOPMENT === 'true' ? true : false

// PINIA
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

// Sentry
Sentry.init({
  app,
  dsn: import.meta.env.VUE_APP_SENTRY_DSN,
  environment: import.meta.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: import.meta.env.VUE_APP_SENTRY_TARGET,
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const head = createHead()
app.use(head).use(i18n).use(router).use(pinia).use(FloatingVue)

// FlOATINGVUE TOOLTIP OPTIONS
FloatingVue.options.themes.tooltip.placement = 'bottom'

//HOTJAR TRACKING

// app.use(VueHotjar, {
//   id: 3167070,
//   isProduction: !DEVELOPMENT,
//   snippetVersion: 6,
// })

setupIcons(app)

console.info(
  '%cEs scheint, als würdest du dich für das Geschehen hinter den Kulissen bei uns interessieren ...\n\n%cÜbrigens: Ziegler Haus sucht aktuell Verstärkung für das Entwickler-Team.\n\n%cBei Interesse einfach eine E-Mail an: bewerbung@ziegler.global',
  'color: #A2C616; font-size: 18px; font-weight: bold;',
  'color: #A2C616; font-size: 18px; font-weight: bold;',
  'font-size: 16px;'
)

app.mount('#app')
