import {useConfigurationStore} from '../store/configuration'
import {useUserStore} from '../store/user'
import router from '../router'
import pathConstants from '../router/constants'
import {useNavigationStore} from '../store/navigation'
import {start as startCaptcha} from '../captcha'

export async function useNewConfig() {
  const configurationStore = useConfigurationStore()
  const userStore = useUserStore()

  // Delete Stores
  await useNavigationStore().$reset()
  await configurationStore.$reset()
  await userStore.$reset()

  // Redirect
  await configurationStore.load().then(async () => {
    // if we are already on house type, create new configuration here
    if (router.currentRoute.value.path === pathConstants.Configurator.HOUSE_KIND) {
      const captchaSolution = await startCaptcha()
      window.location = window.location.pathname
      await configurationStore.createConfiguration(captchaSolution)
    } else {
      await router.push(pathConstants.Configurator.HOUSE_KIND)
    }
  })
}
