import {defineStore} from 'pinia'
import {
  AdditionType,
  FloorPlanType,
  HousePosition,
  HouseSizeType,
  HouseTypeType,
  PropertyChoice,
  RoofTypeType,
} from './configurationTypes'
import HouseTypesApi from '../service/HouseType'
import HouseSizeApi from '../service/HouseSize'
import RoofTypeApi from '../service/RoofType'
import ConfigurationApi, {
  CreateConfigurationType,
  UpdateConfigurationType,
} from '../service/Configuration'
import GroundFloorService from '../service/FloorPlanGroundFloor'
import FirstFloorService from '../service/FloorPlanFirstFloor'
import AdditionApi from '../service/Addition'
import {CreateUserType} from '../service/User'
import i18n from '../i18n'
import {start as startCaptcha} from '../captcha'
import {useEventBus, useUrlSearchParams} from '@vueuse/core'
import pathConstants from '../router/constants'
import router from '../router'
import {eventBus} from '../eventBus'
import {status} from '../util/status'

interface ConfigurationType {
  storeReady: boolean
  houseTypes: Array<HouseTypeType>
  selectedHouseType?: HouseTypeType
  houseSizes: Array<HouseSizeType>
  selectedHouseSize?: HouseSizeType
  roofTypes: Array<RoofTypeType>
  selectedRoofType?: RoofTypeType
  property: PropertyChoice
  propertyPostalcode: string
  propertyCity?: string
  floorPlansGroundFloor: Array<FloorPlanType>
  selectedGroundFloor?: FloorPlanType
  floorPlansFirstFloor: Array<FloorPlanType>
  selectedFirstFloor?: FloorPlanType
  configurationId?: string
  configurationCreatedAt?: string
  configurationUpdatedAt?: string
  house_position?: HousePosition
  additionalServices: Array<AdditionType>
  selectedAdditionalServices: Array<AdditionType>
  floorPlanMirroredVert?: boolean
  floorPlanMirroredHoriz?: boolean
  mirroringInfoSeen: boolean
  completed?: boolean
}

interface ExistingConfigType {
  UID?: number
  createdAt?: string
  updatedAt?: string
  firstname?: string
  surname?: string
  housetype?: number
  housetype_name?: string
  housesize?: number
  housesize_name?: string
  housesize_space?: number
  rooftype?: number
  rooftype_inclination?: number | undefined
  rooftype_name?: string
  property?: PropertyChoice
  property_postalcode?: string
  propertyCity?: string | undefined
  groundfloor?: number
  groundfloor_name?: string
  firstfloor?: number
  firstfloor_name?: string
  house_position?: HousePosition
  additions?: Array<AdditionType>
  floor_vertical_mirrored?: boolean
  floor_horizontal_mirrored?: boolean
  completed?: boolean
}

export const useConfigurationStore = defineStore('configuration', {
  persist: {
    key: 'mirroring-info',
    paths: ['mirroringInfoSeen']
  },
  state: (): ConfigurationType => ({
    storeReady: false,
    houseTypes: [],
    selectedHouseType: undefined,
    houseSizes: [],
    selectedHouseSize: undefined,
    roofTypes: [],
    selectedRoofType: undefined,
    property: PropertyChoice.no,
    propertyPostalcode: undefined,
    propertyCity: undefined,
    floorPlansGroundFloor: [],
    selectedGroundFloor: undefined,
    floorPlansFirstFloor: [],
    selectedFirstFloor: undefined,
    configurationId: undefined,
    configurationCreatedAt: undefined,
    configurationUpdatedAt: undefined,
    house_position: undefined,
    additionalServices: [],
    selectedAdditionalServices: [],
    floorPlanMirroredVert: false,
    floorPlanMirroredHoriz: false,
    mirroringInfoSeen:false,
    completed: false,
  }),
  getters: {
    totalPrice: state => {
      let totalPrice = 0
      totalPrice += state.selectedHouseType ? state.selectedHouseType.price : 0
      totalPrice += state.selectedHouseSize ? state.selectedHouseSize.price : 0
      totalPrice += state.selectedRoofType ? state.selectedRoofType.price : 0
      totalPrice += state.selectedGroundFloor ? state.selectedGroundFloor.price : 0
      totalPrice += state.selectedFirstFloor ? state.selectedFirstFloor.price : 0
      state.selectedAdditionalServices.forEach(addition => (totalPrice += addition.price))
      return totalPrice
    },
    totalPriceHouse: state => {
      let totalPrice = 0
      totalPrice += state.selectedHouseType ? state.selectedHouseType.price : 0
      totalPrice += state.selectedHouseSize ? state.selectedHouseSize.price : 0
      totalPrice += state.selectedRoofType ? state.selectedRoofType.price : 0
      return totalPrice
    },
    totalPriceFloorplans: state => {
      let totalPrice = 0
      totalPrice += state.selectedGroundFloor ? state.selectedGroundFloor.price : 0
      totalPrice += state.selectedFirstFloor ? state.selectedFirstFloor.price : 0
      return totalPrice
    },
    totalPriceAdditions: state => {
      let totalPrice = 0
      for (let i = 0; i < state.selectedAdditionalServices.length; i++) {
        if (
          state.selectedAdditionalServices[i].category !== 'garage' &&
          state.selectedAdditionalServices[i].category !== 'carport'
        )
          totalPrice += state.selectedAdditionalServices[i].price
      }
      return totalPrice
    },
    totalPriceGarageCarport: state => {
      let totalPrice = 0
      for (let i = 0; i < state.selectedAdditionalServices.length; i++) {
        if (
          state.selectedAdditionalServices[i].category === 'garage' ||
          state.selectedAdditionalServices[i].category === 'carport'
        )
          totalPrice += state.selectedAdditionalServices[i].price
      }
      return totalPrice
    },
    getGarages: state => {
      return state.additionalServices.filter(addition => addition.category === 'garage')
    },
    getCarports: state => {
      return state.additionalServices.filter(addition => addition.category === 'carport')
    },
    getSelectedGarage: state => {
      let selectedGarage = state.selectedAdditionalServices.find(
        addition => addition.category === 'garage'
      )
      if (selectedGarage) return selectedGarage
      else
        return {
          name_i18n: {
            de: 'Keine Auswahl',
            en: 'No selection',
          },
        }
    },
    getSelectedCarport: state => {
      let selectedCarport = state.selectedAdditionalServices.find(
        addition => addition.category === 'carport'
      )
      if (selectedCarport) return selectedCarport
      else
        return {
          name_i18n: {
            de: 'Keine Auswahl',
            en: 'No selection',
          },
        }
    },
    getAdditionalServices: state => {
      return state.additionalServices.filter(
        addition =>
          addition.category === 'default' ||
          addition.category === 'floorPlate' ||
          addition.category === 'pv'
      )
    },
    getDefaultAdditionalServices: state => {
      return state.additionalServices.filter(addition => addition.category === 'default')
    },
    getFloorPlateAdditionalServices: state => {
      return state.additionalServices.filter(addition => addition.category === 'floorPlate')
    },
    getPvAdditionalServices: state => {
      return state.additionalServices.filter(addition => addition.category === 'pv')
    },
  },
  actions: {
    async load(configId?: string) {
      if (configId) {
        this.setConfigurationId(configId)
      }

      let existingConfiguration: ExistingConfigType = {}
      if (this.configurationId) {
        await ConfigurationApi.getById(this.configurationId).then(response => {
          existingConfiguration = response.data
        })
      }

      const existingConfigExists = Object.keys(existingConfiguration).length !== 0

      await this.loadHouseTypes()
      if (existingConfigExists)
        this.selectedHouseType = this.houseTypes.find(
          houseType => houseType.id === existingConfiguration.housetype
        )

      await this.loadHouseSizes()
      if (existingConfigExists)
        this.selectedHouseSize = this.houseSizes.find(
          houseSize => houseSize.id === existingConfiguration.housesize
        )

      await this.loadRoofTypes()
      if (existingConfigExists) {
        this.selectedRoofType = this.roofTypes.find(
          roofType => roofType.id === existingConfiguration.rooftype
        )
      }

      await this.loadFirstFloorPlans()
      await this.loadGroundFloorPlans()

      await this.loadAdditionalServices()

      if (existingConfigExists) {
        this.configurationId = existingConfiguration.UID?.toString()
        this.configurationCreatedAt = existingConfiguration.createdAt
        this.configurationUpdatedAt = existingConfiguration.updatedAt
        this.property = existingConfiguration.property || PropertyChoice.no
        this.propertyPostalcode = existingConfiguration.property_postalcode || ''
        this.propertyCity = existingConfiguration.propertyCity
        this.selectedFirstFloor = this.floorPlansFirstFloor.find(
          floorplan => floorplan.id === existingConfiguration.firstfloor
        )
        this.selectedGroundFloor = this.floorPlansGroundFloor.find(
          floorplan => floorplan.id === existingConfiguration.groundfloor
        )
        this.selectedAdditionalServices = existingConfiguration.additions || []
        this.completed = existingConfiguration.completed
        this.floorPlanMirroredVert = existingConfiguration.floor_vertical_mirrored
        this.floorPlanMirroredHoriz = existingConfiguration.floor_horizontal_mirrored
      }

      this.storeReady = true
    },

    async loadFromUrlParam(h: string) {
      const singleIds = h.split('_')
      let error = false

      // load selected elements
      await this.loadHouseTypes()
      this.selectedHouseType = this.houseTypes.find(
        houseType => houseType.id === parseInt(singleIds[0])
      )
      if (!this.selectedHouseType) error = true

      await this.loadHouseSizes()
      this.selectedHouseSize = this.houseSizes.find(
        houseSize => houseSize.id === parseInt(singleIds[1])
      )
      if (!this.selectedHouseSize) error = true

      await this.loadRoofTypes()
      this.selectedRoofType = this.roofTypes.find(
        roofType => roofType.id === parseInt(singleIds[2])
      )
      if (!this.selectedRoofType) error = true

      await this.loadGroundFloorPlans()
      this.selectedGroundFloor = this.floorPlansGroundFloor.find(
        floorPlan => floorPlan.id === parseInt(singleIds[3])
      )
      if (!this.selectedGroundFloor) error = true

      await this.loadFirstFloorPlans()
      if (singleIds.length > 4 && this.selectedHouseType && this.selectedHouseType.floorsMax > 1) {
        this.selectedFirstFloor = this.floorPlansFirstFloor.find(
          floorPlan => floorPlan.id === parseInt(singleIds[4])
        )
        if (!this.selectedFirstFloor) error = true
      }

      // error handling for false h param
      if (error) {
        const bus = useEventBus(eventBus)
        bus.emit({
          name: 'toast',
          data: {
            status: status.warning,
            message: `${i18n.global.t('toastMessages.houseNotAvailable')}`,
            subMessage: `${i18n.global.t('toastMessages.houseNotAvailableSub')}`,
          },
        })
        await router.push(pathConstants.Configurator.HOUSE_KIND)
      }

      await this.loadAdditionalServices()

      // create configuration
      const captchaSolution = await startCaptcha()
      await this.createConfiguration(captchaSolution)

      // add uid as url param
      if (this.configurationId) {
        const params = useUrlSearchParams('history')
        params.uid = this.configurationId.toString()
      }
    },

    async loadHouseTypes() {
      await HouseTypesApi.find().then(response => {
        this.houseTypes = response.data

        // sort by price and alphabet
        this.houseTypes = this.houseTypes.sort((a, b) => {
          return a.name_i18n[i18n.global.locale.value].localeCompare(
            b.name_i18n[i18n.global.locale.value]
          )
        })
        this.houseTypes = this.houseTypes.sort((a, b) => {
          return a.price - b.price
        })

        // check if house type already selected in persisted store
        if (!this.selectedHouseType) {
          this.selectedHouseType = this.houseTypes.find(type => type.default) || this.houseTypes[0]
        }
      })
    },

    async loadHouseSizes() {
      if (this.selectedHouseType) {
        // get sort and select house sizes
        await HouseSizeApi.getByHouseTypeId(this.selectedHouseType.id).then(response => {
          this.houseSizes = response.data

          // sort by price and alphabet
          this.houseSizes = this.houseSizes.sort((a, b) => {
            return a.name_i18n[i18n.global.locale.value].localeCompare(
              b.name_i18n[i18n.global.locale.value]
            )
          })
          this.houseSizes = this.houseSizes.sort((a, b) => {
            return a.price - b.price
          })

          // check if house size already selected in persisted store
          if (
            !this.selectedHouseSize ||
            this.selectedHouseSize.housetype_id !== this.selectedHouseType?.id
          ) {
            this.selectedHouseSize =
              this.houseSizes.find(type => type.default) || this.houseSizes[0]
          }
        })
      }
    },

    async loadRoofTypes() {
      if (this.selectedHouseType && this.selectedHouseSize) {
        // get and select roof type
        await RoofTypeApi.getByHouseTypeIdAndHouseSizeId(
          this.selectedHouseType.id,
          this.selectedHouseSize.id
        ).then(response => {
          this.roofTypes = response.data

          // sort by price and alphabet
          this.roofTypes = this.roofTypes.sort((a, b) => {
            return (
              a.price - b.price ||
              a.name_i18n[i18n.global.locale.value].localeCompare(
                b.name_i18n[i18n.global.locale.value]
              )
            )
          })

          this.selectedRoofType =
            this.roofTypes.find(rooftype => rooftype.id === this.selectedRoofType?.id) ||
            this.roofTypes.find(rooftype => rooftype.default) ||
            this.roofTypes[0]
        })
      }
    },

    async loadGroundFloorPlans() {
      if (this.selectedHouseType && this.selectedHouseSize) {
        await GroundFloorService.getByHouseTypeIdAndHouseSizeId(
          this.selectedHouseType.id,
          this.selectedHouseSize.id
        ).then(response => {
          this.floorPlansGroundFloor = response.data

          // sort by price and alphabet
          this.floorPlansGroundFloor = this.floorPlansGroundFloor.sort((a, b) => {
            return a.name_i18n[i18n.global.locale.value].localeCompare(
              b.name_i18n[i18n.global.locale.value]
            )
          })
          this.floorPlansGroundFloor = this.floorPlansGroundFloor.sort((a, b) => {
            return a.price - b.price
          })
        })
      }
      if (
        !this.selectedGroundFloor ||
        (this.selectedGroundFloor &&
          !this.floorPlansGroundFloor.some(el => el.id === this.selectedGroundFloor?.id))
      ) {
        this.selectedGroundFloor =
          this.floorPlansGroundFloor.find(type => type.default) || this.floorPlansGroundFloor[0]
      }
    },

    async loadFirstFloorPlans() {
      if (this.selectedHouseType && this.selectedHouseSize)
        await FirstFloorService.getByHouseTypeIdAndHouseSizeId(
          this.selectedHouseType.id,
          this.selectedHouseSize.id
        ).then(response => {
          this.floorPlansFirstFloor = response.data

          // sort by price and alphabet
          this.floorPlansFirstFloor = this.floorPlansFirstFloor.sort((a, b) => {
            return a.name_i18n[i18n.global.locale.value].localeCompare(
              b.name_i18n[i18n.global.locale.value]
            )
          })
          this.floorPlansFirstFloor = this.floorPlansFirstFloor.sort((a, b) => {
            return a.price - b.price
          })
        })
      if (
        !this.selectedFirstFloor ||
        (this.selectedFirstFloor &&
          !this.floorPlansFirstFloor.some(el => el.id === this.selectedFirstFloor?.id))
      ) {
        this.selectedFirstFloor =
          this.floorPlansFirstFloor.find(type => type.default) || this.floorPlansFirstFloor[0]
      }
    },
    async loadAdditionalServices() {
      if (!this.selectedHouseSize) return

      await AdditionApi.getByHousesizeId(this.selectedHouseSize.id).then(response => {
        this.additionalServices = response.data
      })

      // sort by price and alphabet
      this.additionalServices = this.additionalServices.sort((a, b) => {
        if (!a.price) {
          return !b.price
            ? a.name_i18n[i18n.global.locale.value].localeCompare(
                b.name_i18n[i18n.global.locale.value]
              )
            : 1
        }

        if (!b.price) {
          return -1
        }

        return a.name_i18n[i18n.global.locale.value].localeCompare(
          b.name_i18n[i18n.global.locale.value]
        )
      })

      this.selectedAdditionalServices.forEach((selectedAddition, index) => {
        // Find the corresponding item from additionalServices that matches the id of the current selectedAddition
        const matchingAddition = this.additionalServices.find(
          addition => addition.id === selectedAddition.id
        )

        // If a matching item is found, replace the corresponding item in selectedAdditionalServices
        if (matchingAddition) {
          this.selectedAdditionalServices[index] = matchingAddition
        }
      })
    },
    async selectHouseType(selected: HouseTypeType) {
      this.selectedHouseType = selected

      await this.loadHouseSizes()
      await this.loadRoofTypes()
      await this.loadGroundFloorPlans()
      await this.loadFirstFloorPlans()
      await this.loadAdditionalServices()
    },
    async selectHouseSize(selected: HouseSizeType) {
      this.selectedHouseSize = selected

      await this.loadRoofTypes()
      await this.loadGroundFloorPlans()
      await this.loadFirstFloorPlans()
      await this.loadAdditionalServices()
    },
    selectRoofType(selected: RoofTypeType) {
      this.selectedRoofType = selected
    },
    selectGarage(garage: AdditionType) {
      // delete current selected garage first
      let current = undefined

      if (garage.category === 'garage') {
        current = this.selectedAdditionalServices.find(x => x.category === 'garage')
      } else if (garage.category === 'carport') {
        current = this.selectedAdditionalServices.find(x => x.category === 'carport')
      }

      if (current) {
        const index = this.selectedAdditionalServices.indexOf(current)
        this.selectedAdditionalServices.splice(index, 1)
      }

      this.selectedAdditionalServices.push(garage)

      // sort by category so that garage is on top of array
      this.selectedAdditionalServices.sort((a, b) => (a.category < b.category ? 1 : -1))
    },
    unselectGarage(garage?: AdditionType) {
      let current = undefined

      if (garage) {
        if (garage.category === 'garage') {
          current = this.selectedAdditionalServices.find(x => x.category === 'garage')
        } else if (garage.category === 'carport') {
          current = this.selectedAdditionalServices.find(x => x.category === 'carport')
        }
      }
      if (current) {
        const index = this.selectedAdditionalServices.indexOf(current)
        this.selectedAdditionalServices.splice(index, 1)
      }
    },
    selectAdditionalService(selected: AdditionType) {
      this.selectedAdditionalServices.push(selected)
    },
    unselectAdditionalService(unselected: AdditionType) {
      const index = this.selectedAdditionalServices.findIndex(
        element => element.id === unselected.id
      )
      if (index >= 0) this.selectedAdditionalServices.splice(index, 1)
    },
    selectProperty(selected: PropertyChoice) {
      this.property = selected
    },
    setPropertyPostalcode(postalcode: string) {
      this.propertyPostalcode = postalcode
    },
    setPropertyCity(city: string) {
      this.propertyCity = city
    },
    selectGroundFloor(plan: FloorPlanType) {
      this.selectedGroundFloor = plan
    },
    selectFirstFloor(plan: FloorPlanType) {
      this.selectedFirstFloor = plan
    },
    toggleMirroringVertical(value: boolean) {
      if (value && !this.mirroringInfoSeen) {
        this.mirroringInfoSeen = true
      }

      this.floorPlanMirroredVert = value
    },
    toggleMirroringHorizontal(value: boolean) {
      if (value && !this.mirroringInfoSeen) {
        this.mirroringInfoSeen = true
      }

     this.floorPlanMirroredHoriz = value
    },
    resetMirroring() {
      this.floorPlanMirroredVert = false
      this.floorPlanMirroredHoriz = false
    },
    setHousePosition(position: HousePosition) {
      this.house_position = position
    },
    async createConfiguration(captchaSolution: string) {
      if (this.selectedHouseType && this.selectedHouseSize && this.selectedRoofType) {
        const config: CreateConfigurationType = {
          housetype: this.selectedHouseType.id,
          housesize: this.selectedHouseSize.id,
          rooftype: this.selectedRoofType.id,
          property: this.property,
          property_postalcode: this.propertyPostalcode,
          property_city: this.propertyCity,
          groundfloor: this.selectedGroundFloor?.id,
          firstfloor:
            this.selectedHouseType && this.selectedHouseType.floorsMax > 1
              ? this.selectedFirstFloor?.id
              : undefined,
          floor_vertical_mirrored: this.floorPlanMirroredVert,
          floor_horizontal_mirrored: this.floorPlanMirroredHoriz,
          captchaSolution: captchaSolution,
          house_position: this.house_position,
          additions: this.selectedAdditionalServices,
        }

        await ConfigurationApi.create(config).then(response => {
          this.configurationId = response.data.UID
          this.configurationCreatedAt = response.data.createdAt
          this.configurationUpdatedAt = response.data.updatedAt
        })
      }
    },
    async updateConfiguration(user?: CreateUserType) {
      if (
        this.selectedHouseType &&
        this.selectedHouseSize &&
        this.selectedRoofType &&
        this.configurationId
      ) {
        const config: UpdateConfigurationType = {
          UID: this.configurationId,
          housetype: this.selectedHouseType?.id,
          housesize: this.selectedHouseSize?.id,
          rooftype: this.selectedRoofType?.id,
          property: this.property,
          property_postalcode: this.propertyPostalcode,
          property_city: this.propertyCity,
          groundfloor: this.selectedGroundFloor?.id,
          firstfloor:
            this.selectedHouseType && this.selectedHouseType.floorsMax > 1
              ? this.selectedFirstFloor?.id
              : undefined,
          floor_vertical_mirrored: this.floorPlanMirroredVert,
          floor_horizontal_mirrored: this.floorPlanMirroredHoriz,
          house_position: this.house_position,
          additions: this.selectedAdditionalServices,
        }

        if (user) config.user = user

        await ConfigurationApi.update(config).then(response => {
          this.configurationUpdatedAt = response.data.updatedAt
        })
      }
    },
    async completeConfiguration(captchaSolution: string) {
      if (this.configurationId)
        await ConfigurationApi.complete(this.configurationId, captchaSolution)
    },
    setConfigurationId(id: string) {
      this.configurationId = id
    },
    async randomizeConfiguration() {
      const rndHouseType = this.houseTypes[Math.floor(Math.random() * this.houseTypes.length)]
      this.selectedHouseType = rndHouseType

      await this.loadHouseSizes()

      const rndHouseSize = this.houseSizes[Math.floor(Math.random() * this.houseSizes.length)]
      this.selectedHouseSize = rndHouseSize

      await this.loadRoofTypes()

      const rndRoofType = this.roofTypes[Math.floor(Math.random() * this.roofTypes.length)]
      this.selectedRoofType = rndRoofType

      await this.loadGroundFloorPlans()
      await this.loadFirstFloorPlans()
    },
  },
})
