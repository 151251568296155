<template>
  <PrivacyContent class="overflow-y-auto p-8 tablet:px-20 tablet:py-10" />
</template>

<script lang="ts" setup>
import PrivacyContent from './PrivacyContent.vue'
import i18n from '../../i18n'
import {useHead} from '@vueuse/head'

useHead({
  title: `${i18n.global.t('pageTitles.privacy')} | ${i18n.global.t('pageTitles.main')}`,
  meta: [
    {
      name: 'description',
      content: `${i18n.global.t('pageMetaDescriptions.privacy')}`,
    },
  ],
})
</script>
