<template>
  <div
    class="col-span-2 row-start-2 bg-white text-justify text-xs row-end-navigationFooter tablet:text-base"
    data-cy="privacy-content"
  >
    <div class="m-auto">
      <p class="mt-4 text-lg font-bold tablet:mt-0 tablet:text-xl">{{ $t('privacy.declaration') }}</p>

      <p class="mt-4 text-sm font-bold tablet:text-base">{{ $t('privacy.responsible.headline') }}</p>

      <p class="my-4 text-sm tablet:text-base">
        {{ $t('privacy.responsible.company') }}<br />
        {{ $t('privacy.responsible.street') }}<br />
        {{ $t('privacy.responsible.location') }}<br />
        {{ $t('privacy.responsible.register') }}<br />
        {{ $t('privacy.responsible.director') }}<br />
        {{ $t('privacy.responsible.phone') }}<br />
        <span v-html="$t('privacy.responsible.email')"></span><br />
      </p>

      <p class="my-4 text-sm tablet:text-base">
        <span class="font-bold">{{ $t('privacy.protectionOfficer.headline') }} </span><br />
        {{ $t('privacy.protectionOfficer.company') }}<br />
        {{ $t('privacy.protectionOfficer.headline') }}<br />
        {{ $t('privacy.protectionOfficer.address') }}<br />
        <span v-html="$t('privacy.protectionOfficer.email')"></span>
        <br /><br />

        {{ $t('privacy.status') }}
      </p>

      <ol class="list-decimal pl-4">
        <li>
          {{ $t('privacy.legalBasis.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentLegalBasis"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.securityMeasures.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentSecurityMeasures"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.disclosure.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentDisclosure"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.contractualServices.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentContractualServices"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.contact.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentContact"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.comments.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentComments"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.newsletter.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentNewsletter"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.accessData.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentAccessData"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.cookies.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentCookies"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.googleAnalytics.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentGoogleAnalytics"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.googleMarketing.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentGoogleMarketing"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.thirdParty.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentThirdParty"></div>
            <ul class="list-disc pl-4">
              <div v-html="contentThirdPartyOverview"></div>
            </ul>
          </ol>
        </li>
        <li>
          {{ $t('privacy.sentry.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentSentry"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.userRights.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentUserRights"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.dataDeletion.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentDataDeletion"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.objection.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentObjection"></div>
          </ol>
        </li>
        <li>
          {{ $t('privacy.changes.header') }}
          <ol class="list-decimal pl-4">
            <div v-html="contentChanges"></div>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { getDataProtectionContentArr } from "../../util/text"

const contentLegalBasis = computed(() => {
  return getDataProtectionContentArr('privacy.legalBasis.content')
})
const contentSecurityMeasures = computed(() => {
  return getDataProtectionContentArr('privacy.securityMeasures.content')
})
const contentDisclosure = computed(() => {
  return getDataProtectionContentArr('privacy.disclosure.content')
})
const contentContractualServices = computed(() => {
  return getDataProtectionContentArr('privacy.contractualServices.content')
})
const contentContact = computed(() => {
  return getDataProtectionContentArr('privacy.contact.content')
})
const contentComments = computed(() => {
  return getDataProtectionContentArr('privacy.comments.content')
})
const contentNewsletter = computed(() => {
  return getDataProtectionContentArr('privacy.newsletter.content')
})
const contentAccessData = computed(() => {
  return getDataProtectionContentArr('privacy.accessData.content')
})
const contentCookies = computed(() => {
  return getDataProtectionContentArr('privacy.cookies.content')
})
const contentGoogleAnalytics = computed(() => {
  return getDataProtectionContentArr('privacy.googleAnalytics.content')
})
const contentGoogleMarketing = computed(() => {
  return getDataProtectionContentArr('privacy.googleMarketing.content')
})
const contentThirdParty = computed(() => {
  return getDataProtectionContentArr('privacy.thirdParty.content')
})
const contentThirdPartyOverview = computed(() => {
  return getDataProtectionContentArr('privacy.thirdParty.contentOverview')
})
const contentSentry = computed(() => {
  return getDataProtectionContentArr('privacy.sentry.content')
})
const contentUserRights = computed(() => {
  return getDataProtectionContentArr('privacy.userRights.content')
})
const contentDataDeletion = computed(() => {
  return getDataProtectionContentArr('privacy.dataDeletion.content')
})
const contentObjection = computed(() => {
  return getDataProtectionContentArr('privacy.objection.content')
})
const contentChanges = computed(() => {
  return getDataProtectionContentArr('privacy.changes.content')
})

</script>

<style scoped>
li {
  padding-top: 1rem;
}
</style>
