import {defineStore} from 'pinia'

interface UnityType {
  show360: boolean
  version: string
  unityReady: boolean
  unityInitialized: boolean
}

export const useUnityStore = defineStore('unity', {
  persist: false,
  state: (): UnityType => ({
    show360: true,
    version: '0.0.0',
    unityReady: false,
    unityInitialized: false
  }),
  getters: {},
  actions: {
    resetShow360() {
      this.show360 = true
    },
    setShow360() {
      this.show360 = false
    },
    setUnityReady() {
      this.unityReady = true
    },
    setUnityInitialized() {
      this.unityInitialized = true
    }
  },
})
