<template>
  <Accordeon
    :expanded="expanded"
    :header="props.header"
    :headerRight="totalPrice > 0 ? formatPrice(totalPrice) : ''"
    class="my-2"
  >
    <template v-slot:contentSlot>
      <div class="flex flex-col">
        <div v-for="(overviewElement, index) in props.overviewContent" :key="index" class="mx-2">
          <!-- Category -->
          <div
            class="py-1 pt-2 text-sm uppercase text-gray-500 tablet:text-xs tablet:font-normal"
            data-cy="overview-accordeon-category"
          >
            {{ getCategoryText(overviewElement.category) }}
          </div>

          <div v-if="overviewElement.choice" class="flex items-center">
            <!-- Choice -->
            <img
              v-if="overviewElement.icon"
              :alt="icon"
              :src="getIconUrl(overviewElement.icon)"
              class="mb-0.5 mr-1 w-7"
            />
            <div
              class="mr-8 py-1 text-sm font-semibold tablet:text-base tablet:font-normal"
              data-cy="overview-accordeon-choice"
              v-html="overviewElement.choice"
            ></div>
            <!-- Prices -->
            <div
              v-if="showPrices && overviewElement.price !== undefined"
              class="ml-auto text-sm font-semibold tablet:text-base tablet:font-normal"
              data-cy="overview-accordeon-prices"
            >
              {{ getPrice(overviewElement.price) }}
            </div>
          </div>
          <!-- Description -->
          <div
            v-if="overviewElement.description && props.showDescription"
            class="py-1 text-sm"
            data-cy="overview-accordeon-description"
            v-html="overviewElement.description"
          ></div>
          <div
            :class="index < overviewContent.length - 1 ? 'border-b-[1px]' : ''"
            class="flex justify-between py-1 pb-2"
          >
            <!-- Change Choices -->
            <div class="flex flex-wrap gap-2">
              <OptionPill
                v-for="item in overviewElement.pills"
                v-tooltip="$t(item.tooltip)"
                :icon="item.icon"
                :text="item.text"
              />
              <OptionPill v-for="item in overviewElement.desc" :text="item[$i18n.locale]" />
            </div>
            <a
              v-if="!readOnly && !addition"
              :data-change="overviewElement.category"
              class="mt-auto cursor-pointer py-1 text-sm text-primary hover:underline"
              data-cy="overview-accordeon-change"
              @click.stop="router.push({path: overviewElement.redirect})"
            >
              {{ $t("common.change") }}
            </a>
          </div>
        </div>
        <a
          v-if="addition && redirect"
          class="text-sm text-primary hover:underline cursor-pointer ml-auto mx-2 pb-3"
          @click.stop="router.push({path: redirect})"
          data-cy="overview-accordeon-change-addition"
        >
          {{ $t("common.change") }}
        </a>
      </div>
    </template>
  </Accordeon>
</template>
<script lang="ts" setup>
import Accordeon from "../common/Accordeon.vue";
import router from "../../router";
import { formatPrice } from "../../util/number";
import { getCategoryText } from "../../util/text";
import { getIconUrl } from "../../util/icons";
import OptionPill from "../common/OptionPill.vue";

const props = defineProps<{
  showPrices?: boolean
  header?: string
  totalPrice?: number
  overviewContent: string[]
  showTotalPrice?: boolean
  readOnly: boolean
  expanded?: boolean
  icon?: string
  showDescription?: boolean
  addition?: boolean
  redirect?: string
}>();

const getPrice = (price: number) => {
  if (price != null && price >= 0) return formatPrice(price)
  else return '* €'
}
</script>
<style></style>