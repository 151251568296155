import Api from '../service/Api'
import {CreateUserType} from './User'
import {HousePosition, AdditionType} from '../store/configurationTypes'

export interface CreateConfigurationType {
  housetype: number
  housesize: number
  rooftype: number
  property?: number
  property_postalcode?: string
  property_city?: string
  groundfloor?: number
  firstfloor?: number
  floor_vertical_mirrored?: boolean
  floor_horizontal_mirrored?: boolean
  captchaSolution: string
  house_position?: HousePosition
  additions?: Array<AdditionType>
}

export interface UpdateConfigurationType {
  UID: string
  user?: CreateUserType
  housetype: number
  housesize: number
  rooftype: number
  property?: number
  property_postalcode?: string
  property_city?: string
  groundfloor?: number
  firstfloor?: number
  floor_vertical_mirrored?: boolean
  floor_horizontal_mirrored?: boolean
  house_position?: HousePosition
  additions?: Array<AdditionType>
}

export default {
  create(data: CreateConfigurationType): Promise<any> {
    return Api.post('configuration', data)
  },
  update(data: UpdateConfigurationType): Promise<any> {
    return Api.put('configuration', data)
  },
  complete(uid: string, captchaSolution: string): Promise<any> {
    return Api.patch(`configuration/complete/${uid}`, {captchaSolution: captchaSolution})
  },
  getById(id: string | Array<string>): Promise<any> {
    return Api.get(`configuration?uid=${id}`)
  },
}
