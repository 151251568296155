import Api from '../service/Api'

export default {
  find(): Promise<any> {
    return Api.get('addition')
  },
  getByHousesizeId(housesizeId: number): Promise<any> {
    return Api.get(`addition/${housesizeId}`)
  },
}
