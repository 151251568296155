import {defineStore} from 'pinia'
import {CreateUserType} from '../service/User'

interface User {
  user: {
    id?: number
    firstname: string
    surname: string
    email: string
    buildtime: number
    city?: string
    created?: string
    housenumber?: string
    mobilenumber?: string
    phonenumber?: string
    postalcode?: string
    street?: string
    notes?: string
    infopackage: boolean
    advice: boolean
  }
  lastVisitedConfiguratorPath: string
  lastVisitedConfiguratorTime?: Date
}

export const useUserStore = defineStore('user', {
  persist: false,
  state: (): User => ({
    user: {
      id: undefined,
      firstname: '',
      surname: '',
      email: '',
      buildtime: 0,
      city: '',
      created: '',
      housenumber: '',
      mobilenumber: '',
      phonenumber: '',
      postalcode: '',
      street: '',
      notes: '',
      advice: false,
      infopackage: false,
    },
    lastVisitedConfiguratorPath: '/',
    lastVisitedConfiguratorTime: undefined,
  }),
  getters: {},
  actions: {
    setLastVisitedConfigurationPath(path: string, time: Date) {
      this.lastVisitedConfiguratorPath = path
      this.lastVisitedConfiguratorTime = time
    },
    setLastVisitedConfigurationTime(time: Date) {
      this.lastVisitedConfiguratorTime = time
    },
    setUser(user: CreateUserType) {
      this.user = user
    },
  },
})
