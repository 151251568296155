<template>
  <div class="relative flex justify-between bg-white grid-in-navbar" data-cy="nav-tabs">
    <Button
      icon-left="fa-solid fa-angle-left "
      flat
      dense
      class="float-left h-full bg-opacity-80"
      @click="prev()"
      v-show="currentStepIndex >= 1 && xOverflow"
    />
    <div
      class="flex overflow-auto"
      ref="tabNavContainer"
      :class="[deviceInfo === DeviceInfo.desktop ? '!overflow-hidden' : '']"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="[
          (index <= currentStepIndex ||
            route.path === pathConstants.Configurator.CONFIGURATION_OVERVIEW ||
            route.path === pathConstants.Configurator.CONFIGURATION_SAVE) &&
            '!border-primary',
          index === currentStepIndex && 'font-semibold text-primary',
        ]"
        :data-action="step.key"
        :data-path="step.path"
        :data-active="currentPath === step.path"
        ref="elements"
        class="group flex cursor-pointer items-center border-b-4 border-transparent px-2 transition tablet:px-6"
        data-cy="single-nav-tab"
        @click="router.push(step.path)"
      >
        <div>
          <div class="w-18 min-w-max group-hover:text-primary">{{ $t(step.key) }}</div>
          <div
            :class="[index === currentStepIndex ? 'font-semibold text-primary' : 'text-gray-500']"
            class="text-xs group-hover:text-primary"
          >
            {{ configurationStore[step.selected]?.name_i18n[i18n.global.locale.value] }}
          </div>
        </div>
      </div>
    </div>
    <Button
      icon-left="fa-solid fa-angle-right"
      flat
      dense
      class="left-0 ml-0 inline-block bg-opacity-80"
      @click="next()"
      v-show="
        !(
          route.path === pathConstants.Configurator.CONFIGURATION_OVERVIEW ||
          route.path === pathConstants.Configurator.CONFIGURATION_SAVE
        ) &&
        xOverflow &&
        currentStepIndex < steps.length - 1
      "
    />
    <p
      class="flex-grow"
      :class="
        route.path === pathConstants.Configurator.CONFIGURATION_OVERVIEW ||
        route.path === pathConstants.Configurator.CONFIGURATION_SAVE
          ? 'border-b-4 !border-b-primary'
          : ''
      "
    ></p>
    <div
      :class="[
        (route.path === pathConstants.Configurator.CONFIGURATION_OVERVIEW ||
          route.path === pathConstants.Configurator.CONFIGURATION_SAVE) &&
          'border-b-4 !border-b-primary',
      ]"
      class="flex items-center gap-5 border-l-2 border-l-gray-100 pl-2.5 pr-5"
      data-cy="overview-save-buttons"
    >
      <Button
        v-if="route.path !== pathConstants.Configurator.CONFIGURATION_OVERVIEW"
        @click="router.push(pathConstants.Configurator.CONFIGURATION_OVERVIEW)"
        data-cy="nav-button-overview"
        data-action="overview"
      >
        {{ $t('routes.overview') }}
      </Button>
      <Button
        v-if="route.path !== pathConstants.Configurator.CONFIGURATION_SAVE"
        @click="router.push(pathConstants.Configurator.CONFIGURATION_SAVE)"
        data-cy="nav-button-save"
        data-action="save"
        >{{ $t('routes.save') }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue'
import pathConstants from '../../router/constants'
import {useConfigurationStore} from '../../store/configuration'
import {useRoute} from 'vue-router'
import Button from '../../components/common/Button.vue'
import {useNavigationStore} from '../../store/navigation'
import useDeviceInfo, {DeviceInfo} from '../../composables/useDeviceInfo'
import {useWindowResize} from '../../composables/useWindowResize'
import i18n from '../../i18n'
import router from '../../router'

const configurationStore = useConfigurationStore()
const navigationStore = useNavigationStore()

const route = useRoute()
const currentPath = computed(() => route.path)

const elements = ref([])
const tabNavContainer = ref<HTMLElement>()
const xOverflow = ref<boolean>(false)
const deviceInfo = useDeviceInfo()

const steps = computed(() => navigationStore.allConfiguratorSteps)
const currentStepIndex = computed(() => steps.value.findIndex(el => el.path === currentPath.value))
const selectedHouseSize = computed(() => configurationStore.selectedHouseSize)

const prev = () => {
  if (currentStepIndex.value > 0) {
    elements.value[currentStepIndex.value - 1].click()
  }
}

const next = () => {
  if (currentStepIndex.value < elements.value.length) {
    elements.value[currentStepIndex.value + 1].click()
  }
}

const checkxOverflow = el => {
  return el.scrollWidth > el.clientWidth
}

const checkShowFirstFloor = () => {
  if (selectedHouseSize.value && selectedHouseSize.value.floors > 1) {
    navigationStore.addFirstFloorStep()
  } else if (selectedHouseSize.value && selectedHouseSize.value.floors == 1) {
    navigationStore.removeFirstFloorStep()
  }
  setTimeout(() => {
    xOverflow.value = checkxOverflow(tabNavContainer.value)
  }, 50)
}

checkShowFirstFloor()

watch(selectedHouseSize, () => {
  checkShowFirstFloor()
})

watch(currentStepIndex, () => {
  xOverflow.value = checkxOverflow(tabNavContainer.value)
  setTimeout(() => {
    if (elements.value[currentStepIndex.value] < currentStepIndex)
      elements.value[currentStepIndex.value].scrollIntoView({behavior: 'smooth', inline: 'center'})
  }, 250)
})

useWindowResize(() => {
  xOverflow.value = checkxOverflow(tabNavContainer.value)
})
</script>
