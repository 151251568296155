import i18n from '../i18n'
import {ConfigurationCategory, i18nType, PropertyChoice} from '../store/configurationTypes'

export function getBuildText(userBuildtime: number) {
  switch (userBuildtime) {
    case 0:
      return `${i18n.global.t('configSave.buildTimeOptions.0')}`
    case 1:
      return `${i18n.global.t('configSave.buildTimeOptions.1')}`
    case 2:
      return `${i18n.global.t('configSave.buildTimeOptions.2')}`
    case 3:
      return `${i18n.global.t('configSave.buildTimeOptions.3')}`
    default:
      return ''
  }
}

export function getPropertyText(propertyChoice: PropertyChoice) {
  switch (propertyChoice) {
    case PropertyChoice.no:
      return `${i18n.global.t('property.no')}`
    case PropertyChoice.yes:
      return `${i18n.global.t('property.yes')}`
    case PropertyChoice.reserved:
      return `${i18n.global.t('property.reserved')}`
    case PropertyChoice.inView:
      return `${i18n.global.t('property.inView')}`
    default:
      return ''
  }
}

export function getFloorPlanDescriptions(desciptions: i18nType[] | undefined) {
  let descArr: string[] = []
  if (desciptions) {
    desciptions.forEach((desc: i18nType) => {
      // @ts-ignore
      descArr.push(desc[i18n.global.locale.value])
    })
    return descArr.join(', ')
  }
}

export function getInclusiveServicesDescArr(key: string) {
  let descArr: string[] = []
  for (let i = 0; i < i18n.global.tm(key).length; i++) {
    descArr.push(`<li style='margin-left: 10px'> ${i18n.global.t(key + '[' + i + ']')} </li>`)
  }
  return descArr.join('')
}

export function getDataProtectionContentArr(key: string) {
  let contentArr: string[] = []
  for (let i = 0; i < i18n.global.tm(key).length; i++) {
    contentArr.push(`<li style="padding-top:1rem"> ${i18n.global.t(key + '[' + i + ']')} </li>`)
  }
  return contentArr.join('')
}

export function getCategoryText(value: ConfigurationCategory) {
  switch (value) {
    case ConfigurationCategory.houseType:
      return `${i18n.global.t('houseType.houseType')}`
    case ConfigurationCategory.houseSize:
      return `${i18n.global.t('houseSize.houseSize')}`
    case ConfigurationCategory.roofType:
      return `${i18n.global.t('roofType.roofType')}`
    case ConfigurationCategory.propertyPostcode:
      return `${i18n.global.t('property.postcode')}`
    case ConfigurationCategory.floorplan:
      return `${i18n.global.t('floorplans.floorplan')}`
    case ConfigurationCategory.groundFloor:
      return `${i18n.global.t('groundFloor.groundFloor')}`
    case ConfigurationCategory.firstFloor:
      return `${i18n.global.t('firstFloor.firstFloor')}`
  }
}
