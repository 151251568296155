interface ConstantsType {
  readonly HOME: string
  readonly EXISTING_CONFIGURATION: string
  readonly OVERVIEW: string
  readonly SUCCESS: string
  readonly PRIVACY: string
  readonly FAQ: string
  readonly EXPORT_IMAGES: string
  readonly Configurator: {
    readonly ROOT: string
    readonly HOUSE_TYPE: string
    readonly HOUSE_KIND: string
    readonly ROOF_TYPE: string
    readonly GROUND_FLOOR: string
    readonly FIRST_FLOOR: string
    readonly GARAGE_CARPORT: string
    readonly ADDITIONAL_SERVICES: string
    readonly CONFIGURATION_OVERVIEW: string
    readonly CONFIGURATION_SAVE: string
  }
  readonly NOT_FOUND: string
  readonly CATCH_ALL: string
}

const constants: ConstantsType = {
  HOME: '/',
  EXISTING_CONFIGURATION: '/existing-configuration',
  OVERVIEW: '/overview',
  SUCCESS: '/success',
  PRIVACY: '/privacy',
  FAQ: '/faq',
  EXPORT_IMAGES: '/export-images',
  Configurator: {
    ROOT: '/configuration',
    HOUSE_TYPE: '/configuration/house-type',
    HOUSE_KIND: '/configuration/house-kind',
    ROOF_TYPE: '/configuration/roof-type',
    GROUND_FLOOR: '/configuration/ground-floor',
    FIRST_FLOOR: '/configuration/first-floor',
    GARAGE_CARPORT: '/configuration/carport-garage',
    ADDITIONAL_SERVICES: '/configuration/additional-services',
    CONFIGURATION_OVERVIEW: '/configuration/overview',
    CONFIGURATION_SAVE: '/configuration/save',
  },
  NOT_FOUND: '/404',
  CATCH_ALL: '/:catchAll(.*)',
}

export default constants
