import {defineStore} from 'pinia'
import pathConstants from '../router/constants'
import router from '../router'

export type StepType = {
  key: string
  path: string
  selected?: any
  active?: boolean
}

interface Navigation {
  steps: StepType[]
  overviewStep: StepType
  homeStep: StepType
  successStep: StepType
}

export const useNavigationStore = defineStore('navigation', {
  persist: false,
  state: (): Navigation => ({
    steps: [
      {
        key: 'routes.houseKind',
        path: pathConstants.Configurator.HOUSE_KIND,
        selected: 'selectedHouseType',
        active: true,
      },
      {
        key: 'routes.houseType',
        path: pathConstants.Configurator.HOUSE_TYPE,
        selected: 'selectedHouseSize',
        active: true,
      },
      {
        key: 'routes.roofType',
        path: pathConstants.Configurator.ROOF_TYPE,
        selected: 'selectedRoofType',
        active: true,
      },
      {
        key: 'routes.groundFloor',
        path: pathConstants.Configurator.GROUND_FLOOR,
        selected: 'selectedGroundFloor',
        active: true,
      },
      {
        key: 'routes.firstFloor',
        path: pathConstants.Configurator.FIRST_FLOOR,
        selected: 'selectedFirstFloor',
        active: false,
      },
      {
        key: 'routes.garageCarport',
        path: pathConstants.Configurator.GARAGE_CARPORT,
        selected: '',
        active: true,
      },
      {
        key: 'routes.additionalServices',
        path: pathConstants.Configurator.ADDITIONAL_SERVICES,
        selected: '',
        active: true,
      },
    ],
    overviewStep: {
      key: 'routes.overview',
      path: pathConstants.Configurator.CONFIGURATION_OVERVIEW,
    },
    successStep: {
      key: 'routes.save',
      path: pathConstants.Configurator.CONFIGURATION_SAVE,
    },
    homeStep: {
      key: 'routes.home',
      path: pathConstants.HOME,
    },
  }),
  getters: {
    allSteps: state => {
      return [...state.steps, state.overviewStep, state.homeStep, state.successStep]
    },
    allConfiguratorSteps: state => {
      return state.steps.filter(step => step.active)
    },
    next: state => {
      const filteredSteps = state.steps.filter(step => step.active)
      const currentIndex = filteredSteps.findIndex(
        step => step.path === router.currentRoute.value.path
      )

      if (router.currentRoute.value.path === pathConstants.Configurator.CONFIGURATION_OVERVIEW)
        return state.successStep
      else if (currentIndex === filteredSteps.length - 1) return state.overviewStep
      else return filteredSteps[currentIndex + 1]
    },
    prev: state => {
      const filteredSteps = state.steps.filter(step => step.active)
      const currentIndex = filteredSteps.findIndex(
        step => step.path === router.currentRoute.value.path
      )

      if (router.currentRoute.value.path === pathConstants.Configurator.CONFIGURATION_OVERVIEW)
        return filteredSteps[filteredSteps.length - 1]
      else if (router.currentRoute.value.path === pathConstants.Configurator.CONFIGURATION_SAVE)
        return state.overviewStep
      else if (currentIndex === 0) return state.homeStep
      else return filteredSteps[currentIndex - 1]
    },
    currentStep() {
      // @ts-ignore
      return this.allSteps.find(el => el.path === router.currentRoute.value.path)
    },
  },
  actions: {
    addFirstFloorStep() {
      this.steps.forEach(step => {
        if (step.key === 'routes.firstFloor') step.active = true
      })
    },
    removeFirstFloorStep() {
      this.steps.forEach(step => {
        if (step.key === 'routes.firstFloor') step.active = false
      })
    },
  },
})
