<template>
  <div class="carousel-inner relative h-full w-full overflow-hidden">
    <div
      v-for="(entry, i) in data"
      :id="`slide-${i + 1}`"
      :key="i + 1"
      :class="[active === i + 1 ? 'active' : 'inactive', init && 'init']"
      class="carousel-item absolute h-full w-full"
      data-cy="carousel-item"
    >
      <div
        :style="[`background-image: url('${entry.imgpath}')`, bgParalax]"
        class="h-full w-full bg-cover"
        style="background-position: 50% 0%"
      ></div>
      <div
        class="carousel-header absolute top-10 left-3 w-fit max-w-[95%] text-left uppercase text-gray-700 tablet:top-[15%] tablet:left-24 tablet:max-w-[90%]"
      >
        <div class="header w-fit overflow-hidden bg-white bg-opacity-90 p-2 px-4 text-xl font-bold tablet:text-5xl">
          {{ $t(`home.${entry.i18nkey}.header`) }}
        </div>
        <div
          class="subheader text-md w-fit overflow-hidden bg-white bg-opacity-90 p-2 px-4 tablet:text-2xl"
          style="box-shadow: inset 0px 20px 30px -20px rgba(0, 0, 0, 0.5)"
        >
          {{ $t(`home.${entry.i18nkey}.subheader`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useEventListener } from '@vueuse/core'

interface ImgDataType {
  data: {
    imgpath: string
    i18nkey: string
  }[]
  paralax?: boolean
}

const props = defineProps<ImgDataType>()

const init = ref(true)
const active = ref(1)

onMounted(() => {
  let i = 1
  setInterval(() => {
    if (init.value) init.value = false
    i++
    if (i > props.data.length) i = 1
    active.value = i
  }, 8000)
})

const scale = 1.16
const bgParalax = ref({
  transform: `translateX(0px) translateY(0px) scale(${props.paralax ? scale : 1})`,
})
const movementStrength = 15

// Paralax Eventlistener
if (props.paralax) {
  useEventListener(window, 'mousemove', e => {
    const X = (((e.pageX / window.innerWidth) * 100 * movementStrength * 2) / 100 - movementStrength) * -1
    const Y = (((e.pageY / window.innerWidth) * 100 * movementStrength * 2) / 100 - movementStrength) * -1

    bgParalax.value = {
      transform: `translateX(${X}px) translateY(${Y}px) scale(${scale})`,
    }
  })

  useEventListener(window, 'touchmove', e => {
    const X = (((e.touches[0].pageX / window.innerWidth) * 100 * movementStrength * 2) / 100 - movementStrength) * -1
    const Y = (((e.touches[0].pageY / window.innerWidth) * 100 * movementStrength * 2) / 100 - movementStrength) * -1

    bgParalax.value = {
      transform: `translateX(${X}px) translateY(${Y}px) scale(${scale})`,
    }
  })
}
</script>

<style scoped>
.carousel-item:not(.init) {
  animation-duration: 3s;
  animation-fill-mode: both;
}

.carousel-item.active {
  animation-name: slide-in;
  opacity: 1;
}

.carousel-item.inactive {
  animation-name: slide-out;
  opacity: 0;
}

@keyframes slide-in {
  0% {
    clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 120% 100%);
    opacity: 0;
  }
  0.01% {
    opacity: 1;
  }
  100% {
    clip-path: polygon(-20% 0%, 100% 0%, 100% 100%, 0% 100%);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    clip-path: polygon(100% 0, 0 0, 0 100%, 120% 100%);
    opacity: 1;
  }
  99.99% {
    opacity: 1;
  }
  100% {
    clip-path: polygon(-20% 0, 0 0, 0 100%, 0% 100%);
    opacity: 0;
  }
}

.carousel-header > div {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
  transform: translateX(100px);
}

.carousel-item.active .carousel-header > div {
  animation-name: header-slide-in;
  animation-timing-function: ease;
}

.carousel-item.active.carousel-header .header {
  animation-delay: 0.2s;
}

.carousel-item.active:not(.init) .carousel-header .header {
  animation-delay: 2s;
}

.carousel-item.active .carousel-header .subheader {
  animation-delay: 0.5s;
}

.carousel-item.active:not(.init) .carousel-header .subheader {
  animation-delay: 2.5s;
}

.carousel-item.inactive .carousel-header > div {
  animation-name: header-slide-out;
  animation-timing-function: ease;
  opacity: 1;
  transform: translateX(0);
}

.carousel-item.inactive .carousel-header .subheader {
  animation-delay: 0.2s;
}

@keyframes header-slide-in {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes header-slide-out {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}
</style>
