
    var hostname = window.location.hostname
    var script = document.createElement('script')
    var apiKey = import.meta.env.VUE_APP_CF_API_KEY

    // shadow request for acquiring the current headers
    var req = new XMLHttpRequest()
    req.open('GET', '/check-access', false)
    req.send(null)
    // get headers as concatenated string
    var headers = req.getAllResponseHeaders().toLowerCase()

    // Get URL-Params
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    // check if internal-access header is true and no tracking is forced to conditionally deliver consent.js
    if (!headers.includes('internal-access: true')) {
      script.src = 'https://consent.cookiefirst.com/sites/' + hostname + '-' + apiKey + '/consent.js'
    } else {
      if (urlParams.has('forceTracking')) {
        script.src = 'https://consent.cookiefirst.com/sites/' + hostname + '-' + apiKey + '/consent.js'
      }
    }

    document.head.appendChild(script)
  