export enum DeviceInfo {
  desktop = 'desktop',
  Android = 'android',
  iOS = 'ios',
  other = 'other',
}

export default function useDeviceInfo(): DeviceInfo {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const platform = window.navigator.platform.toLowerCase()

  const macosPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k']
  const windowsPlatforms = ['win32', 'win64', 'windows', 'wince']
  const iosPlatforms = ['iphone', 'ipad', 'ipod']
  let device = DeviceInfo.other

  if (macosPlatforms.indexOf(platform) !== -1) {
    device = DeviceInfo.desktop
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    device = DeviceInfo.iOS
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    device = DeviceInfo.desktop
  } else if (/android/.test(userAgent)) {
    device = DeviceInfo.Android
  } else if (device == DeviceInfo.other && /linux/.test(platform)) {
    device = DeviceInfo.desktop
  }

  return device
}
