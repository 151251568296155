<template>
  <div data-cy="appbar-total-price">
    {{ formatPrice(displayNumber) }}
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { formatPrice } from '../../util/number'

interface Props {
  number: number
  euro?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  number: 0,
  euro: false,
})

const displayNumber = ref(props.number)
const interval = ref(0)

const ready = computed(() => (displayNumber.value = props.number ? props.number : 0))

watch(
  () => props.number,
  () => {
    clearInterval(interval.value)

    if (props.number === displayNumber.value) {
      return
    }

    interval.value = window.setInterval(() => {
      // console.log(displayNumber.value, props.number)

      if (displayNumber.value !== props.number) {
        var change = (props.number - displayNumber.value) / 3

        if (Math.abs(change) < 1) {
          displayNumber.value = props.number
          change = 0
        }

        change = change >= 0 ? Math.ceil(change) : Math.floor(change)
        displayNumber.value = displayNumber.value + change
      }
    }, 20)
  }
)
</script>