<template>
  <Dialogue
    :header="$t('navigation.priceOverview')"
    :show-dialogue="showModal"
    stack-on-top
    @close="closeModal"
  >
    <template v-slot:content>
      <div
        class="max-w-[95vh] text-left text-sm tablet:max-h-[530px] tablet:text-base"
        data-cy="price-info"
      >
        <div>
          <div class="font-bold">{{ `${i18n.global.t('navigation.priceOverviewH2')}` }}</div>
          <table class="mt-4 w-full">
            <tbody>
              <tr v-for="(price, index) in priceList" :key="index" class="tablet:border-b">
                <td class="py-2 tablet:py-4">{{ categoryText(price.category) }}</td>
                <td>{{ price.choice }}</td>
                <td class="text-right font-bold" data-cy="price-info-price">
                  {{ formatPrice(price.price) }}
                </td>
              </tr>
              <tr v-for="(price, index) in additions" :key="index" class="tablet:border-b">
                <td class="py-2 tablet:py-4">{{ categoryText(price.category) }}</td>
                <td>{{ price.choice }}</td>
                <td class="text-right font-bold">
                  {{ price.price }}
                </td>
              </tr>
              <tr class="border-t border-black font-bold tablet:border-t-2">
                <td class="pt-4">{{ $t('navigation.total') }}</td>
                <td></td>
                <td class="pt-4 text-right font-bold" data-cy="price-info-total-price">
                  {{ formatPrice(totalPrice) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p v-if="showAdditionalServicesStarText" class="pt-1 text-xs">
          *{{ $t('inclusiveServices.additionalServices.priceInfo') }}
        </p>
        <p class="pt-1 text-xs">**{{ $t('configOverview.priceExpectation') }}</p>

        <p
          class="mt-6 font-bold tablet:mt-12"
          v-html="`${i18n.global.t('inclusiveServices.pricelistHeader')}`"
        ></p>

        <OverviewAccordeon
          :header="`${i18n.global.t(
            'inclusiveServices.generalHeader'
          )} <span class='text-primary'> ${i18n.global.t('inclusiveServices.mainHeading')}`"
          :overviewContent="inclusiveServices"
          :show-prices="false"
          :totalPrice="null"
          data-cy="inclusiveServices-accordeon"
          dense
          read-only
          showDescription
        >
        </OverviewAccordeon>

        <p
          class="col-span-2 mt-6 text-justify tablet:mt-12"
          v-html="$t('navigation.priceInfo')"
        ></p>
      </div>
    </template>
    <template v-slot:footer>
      <Button :msg="$t('common.close')" data-cy="price-info-ok" primary @click="closeModal" />
    </template>
  </Dialogue>
</template>
<script lang="ts" setup>
import i18n from '../../i18n'
import {computed} from 'vue'
import {ConfigurationCategory, ConfigurationListElement} from '../../store/configurationTypes'
import Dialogue from '../common/Dialogue.vue'
import OverviewAccordeon from '../configurationOverview/OverviewAccordeon.vue'
import Button from '../common/Button.vue'
import {ICON} from '../../util/icons'
import {useConfigurationStore} from '../../store/configuration'
import {formatPrice} from '../../util/number'
import {getInclusiveServicesDescArr} from '../../util/text'

const props = defineProps<{
  showModal: boolean
  closeModal: Function
}>()

const configurationStore = useConfigurationStore()

const selectedHouseType = computed(() => configurationStore.selectedHouseType)
const selectedHouseSize = computed(() => configurationStore.selectedHouseSize)
const selectedRoofType = computed(() => configurationStore.selectedRoofType)
const selectedGroundFloor = computed(() => configurationStore.selectedGroundFloor)
const selectedFirstFloor = computed(() => configurationStore.selectedFirstFloor)
const selectedAdditions = computed(() => configurationStore.selectedAdditionalServices)
const totalPrice = computed(() => configurationStore.totalPrice)

const priceList = computed(() => {
  let retVal: ConfigurationListElement[] = []

  // House Type
  retVal.push({
    category: ConfigurationCategory.houseType,
    price: selectedHouseType.value?.price,
    choice: `${selectedHouseType.value?.name_i18n[i18n.global.locale.value]}`,
  })

  // House Size
  retVal.push({
    category: ConfigurationCategory.houseSize,
    price: selectedHouseSize.value?.price,
    choice: `${selectedHouseSize.value?.name_i18n[i18n.global.locale.value]}`,
  })
  // Roof Type
  retVal.push({
    category: ConfigurationCategory.roofType,
    price: selectedRoofType.value?.price,
    choice: `${selectedRoofType.value?.name_i18n[i18n.global.locale.value]}`,
  })

  // Check if First Floor exists
  if (selectedHouseSize.value && selectedHouseSize.value.floors > 1) {
    // Ground Floor
    retVal.push({
      category: ConfigurationCategory.groundFloor,
      price: selectedGroundFloor.value?.price,
      choice: `${selectedGroundFloor.value?.name_i18n[i18n.global.locale.value]}`,
    })
    // First Floor
    retVal.push({
      category: ConfigurationCategory.firstFloor,
      price: selectedFirstFloor.value?.price,
      choice: `${selectedFirstFloor.value?.name_i18n[i18n.global.locale.value]}`,
    })
  } else {
    // Name it only floorplan for bungalow
    retVal.push({
      category: ConfigurationCategory.floorplan,
      price: selectedGroundFloor.value?.price,
      choice: `${selectedGroundFloor.value?.name_i18n[i18n.global.locale.value]}`,
    })
  }

  return retVal
})

const additions = computed(() => {
  let retVal: ConfigurationListElement[] = []

  // Check if additional services are selected
  if (selectedAdditions.value)
    for (let i = 0; i < selectedAdditions.value.length; i++) {
      retVal.push({
        category: getCategory(selectedAdditions.value[i]),
        price: getPrice(selectedAdditions.value[i].price),
        choice: `${selectedAdditions.value[i].name_i18n[i18n.global.locale.value]}`,
      })
    }

  return retVal.sort((a, b) => {
    // category
    // carport before garage
    if (
      b.category === ConfigurationCategory.carport &&
      a.category === ConfigurationCategory.garage
    ) {
      return 1
    }

    if (
      a.category === ConfigurationCategory.carport ||
      a.category === ConfigurationCategory.garage
    ) {
      return -1
    }

    if (
      b.category === ConfigurationCategory.carport ||
      b.category === ConfigurationCategory.garage
    ) {
      return 1
    }

    if (a.price === '* €') {
      return b.price === '* €' ? a.choice.localeCompare(b.choice) : 1
    }

    if (b.price === '* €') {
      return -1
    }

    return a.choice.localeCompare(b.choice)
  })
})

const getPrice = (price: number) => {
  if (price > 0) return formatPrice(price)
  else return '* €'
}

const getCategory = (addition: any) => {
  if (addition.category === 'carport') return ConfigurationCategory.carport
  else if (addition.category === 'garage') return ConfigurationCategory.garage
  else return ConfigurationCategory.addition
}

const inclusiveServices = computed(() => {
  let retVal: Array<ConfigurationListElement> = []

  // Inclusive Services
  retVal.push({
    price: 0,
    icon: ICON.basis,
    choice: `${i18n.global.t(
      'inclusiveServices.generalHeader'
    )} <span class='text-primary'>${i18n.global.t('inclusiveServices.basis.header')}</span>`,
    description: `<ul class='list-disc'>${getInclusiveServicesDescArr(
      'inclusiveServices.basis.message'
    )}</ul>`,
  })
  retVal.push({
    price: 0,
    icon: ICON.service,
    choice: `${i18n.global.t(
      'inclusiveServices.generalHeader'
    )} <span class='text-primary'>${i18n.global.t('inclusiveServices.service.header')}</span>`,
    description: `<ul class='list-disc'> ${getInclusiveServicesDescArr(
      'inclusiveServices.service.message'
    )}</ul>`,
  })
  retVal.push({
    price: 0,
    icon: ICON.comfort,
    choice: `${i18n.global.t(
      'inclusiveServices.generalHeader'
    )} <span class='text-primary'>${i18n.global.t('inclusiveServices.comfort.header')}</span>`,
    description: `<ul class='list-disc'> ${getInclusiveServicesDescArr(
      'inclusiveServices.comfort.message'
    )}</ul>`,
  })
  retVal.push({
    price: 0,
    icon: ICON.safety,
    choice: `${i18n.global.t(
      'inclusiveServices.generalHeader'
    )} <span class='text-primary'>${i18n.global.t('inclusiveServices.safety.header')}</span>`,
    description: `<ul class='list-disc'>${getInclusiveServicesDescArr(
      'inclusiveServices.safety.message'
    )}</ul>`,
  })
  retVal.push({
    price: 0,
    icon: ICON.energySaving,
    choice: `${i18n.global.t(
      'inclusiveServices.generalHeader'
    )} <span class='text-primary'>${i18n.global.t('inclusiveServices.energySaving.header')}</span>`,
    description: `<ul class='list-disc'> ${getInclusiveServicesDescArr(
      'inclusiveServices.energySaving.message'
    )}</ul>`,
  })
  retVal.push({
    price: 0,
    icon: ICON.priceWarranty,
    choice: `${i18n.global.t(
      'inclusiveServices.generalHeader'
    )} <span class='text-primary'>${i18n.global.t(
      'inclusiveServices.priceWarranty.header'
    )}</span>`,
    description: `${i18n.global.t('inclusiveServices.priceWarranty.message')}`,
  })
  return retVal
})

const categoryText = (value: ConfigurationCategory) => {
  switch (value) {
    case ConfigurationCategory.houseType:
      return `${i18n.global.t('houseType.houseType')}`
    case ConfigurationCategory.houseSize:
      return `${i18n.global.t('houseSize.houseSize')}`
    case ConfigurationCategory.roofType:
      return `${i18n.global.t('roofType.roofType')}`
    case ConfigurationCategory.floorplan:
      return `${i18n.global.t('floorplans.floorplan')}`
    case ConfigurationCategory.groundFloor:
      return `${i18n.global.t('groundFloor.groundFloor')}`
    case ConfigurationCategory.firstFloor:
      return `${i18n.global.t('firstFloor.firstFloor')}`
    case ConfigurationCategory.addition:
      return `${i18n.global.t('inclusiveServices.additionalServices.addition')}`
    case ConfigurationCategory.carport:
      return `${i18n.global.t('inclusiveServices.additionalServices.carport')}`
    case ConfigurationCategory.garage:
      return `${i18n.global.t('inclusiveServices.additionalServices.garage')}`
  }
}

const showAdditionalServicesStarText = computed(() => {
  return selectedAdditions.value.some(el => el.price == null)
})
</script>

<style>
.table-with-border tr:nth-last-child(2) {
  border-top: 1px solid black;
}
</style>
