import {WidgetInstance} from 'friendly-challenge'

const captchaEnabled = import.meta.env.VUE_APP_CAPTCHA_ENABLED === 'true'

export function start(): Promise<string> {
  console.log('Capcha Enable', captchaEnabled)
  if (captchaEnabled) {
    return startFriendlyCaptcha()
  } else {
    return startDummyCaptcha()
  }
}

function startFriendlyCaptcha(): Promise<string> {
  const element = document.createElement('div')
  element.setAttribute('style', 'display: none;')
  element.setAttribute('id', 'friendly-captcha-container')
  document.getElementsByTagName('body')[0].appendChild(element)

  let captcha: WidgetInstance | null = null!

  return new Promise<string>((resolve, reject) => {
    captcha = new WidgetInstance(element, {
      startMode: 'none',
      doneCallback: solution => resolve(solution),
      errorCallback: e => reject(e),
      readyCallback: () => {
        captcha?.start()
      },
      sitekey: import.meta.env.VUE_APP_CAPTCHA_SITEKEY,
    })
  }).finally(() => {
    captcha?.destroy()
    element.remove()
  })
}

function startDummyCaptcha(): Promise<string> {
  return new Promise<string>(resolve => resolve('dummy captcha solution'))
}
