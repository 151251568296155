import {defineStore} from 'pinia'

interface AppStoreType {
  version: string
  devMode: boolean
}

export const useAppStore = defineStore('app', {
  // save devMode in localStorage in Development
  persist: import.meta.env.VUE_APP_DEVELOPMENT
    ? {
        key: 'dev-mode',
        paths: ['devMode'],
      }
    : false,
  state: (): AppStoreType => ({
    version: '0.0.0',
    devMode: false,
  }),
  getters: {},
  actions: {
    async setDevMode(toggle: boolean) {
      this.devMode = toggle
    },
  },
})
