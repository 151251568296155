<template>
  <div
    class="fixed inset-0 z-[99] flex items-center justify-center drop-shadow-md backdrop-blur"
    :style="`background-color: rgb(0 0 0 / ${opacity}%)`"
    data-cy="unity-loading-screen"
  >
    <div class="w-[70%] -translate-y-12 tablet:w-1/2">
      <div class="relative h-32">
        <div
          class="text-element absolute bottom-0 w-full text-left text-xl leading-8 text-white opacity-0"
          v-for="(text, i) in loadingTextArray"
          :key="i"
          :style="`animation-delay: ${i * animationDuration}s; animation-duration: ${animationDuration}s;`"
        >
          {{ text }}
        </div>
        <div
          class="text-element-end absolute bottom-0 w-full text-left text-xl leading-8 text-white opacity-0"
          :style="`animation-delay: ${animationDuration * loadingTextArray.length}s; animation-duration: ${animationDuration}s;`"
        >
          {{ i18n.global.t('unityloading.endMessage') }}
        </div>
      </div>
      <div class="mt-4 h-4 w-full">
        <div class="relative h-full rounded-lg bg-primary" :style="`width: ${percent}%`">
          <p class="tr absolute -right-16 top-1/2 -translate-y-1/2 text-xl text-primary">{{ percent }} %</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, watchEffect } from 'vue'
import i18n from '../../i18n'

const props = defineProps<{
  percent: number
}>()

const animationDuration = 4

const opacity = ref(100)

function map(current: number, in_min: number, in_max: number, out_min: number, out_max: number): number {
  const mapped: number = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  return mapped < out_min ? out_min : mapped > out_max ? out_max : mapped
}

watchEffect(() => {
  opacity.value = 100 - map(props.percent, 0, 100, 0, 70)
})

let loadingTextArray: string[] = []
for (let i = 0; i < i18n.global.tm('unityloading.messages').length; i++) {
  loadingTextArray.push(i18n.global.t('unityloading.messages' + '[' + i + ']'))
}
</script>

<style scoped>
.text-element {
  animation-name: text;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes text {
  0% {
    opacity: 0;
    left: 50px;
  }
  30% {
    opacity: 1;
    left: 0px;
  }
  70% {
    opacity: 1;
    left: 0px;
  }
  100% {
    opacity: 0;
    left: -50px;
  }
}

.text-element-end {
  animation-name: endtext;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes endtext {
  0% {
    opacity: 0;
    left: 50px;
  }
  30% {
    opacity: 1;
    left: 0px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
</style>
