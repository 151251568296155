<template>
  <div class="flex h-full w-full items-center gap-1 grid-in-progressBar">
    <div
      v-for="(step, i) in steps"
      class="step h-full flex-grow rounded-md"
      :class="i <= index || index === -1 ? 'bg-primary' : 'bg-gray-200'"
    ></div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useNavigationStore} from '../../store/navigation'
import router from '../../router'

const navigationStore = useNavigationStore()
const steps = computed(() => navigationStore.allConfiguratorSteps)

const index = computed(() =>
  steps.value.findIndex(el => el.path === router.currentRoute.value.path)
)
</script>

<style>
.step:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.step:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
