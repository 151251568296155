import Api from '../service/Api'

export default {
  getByHouseTypeIdAndHouseSizeId(houseTypeId: number, houseSizeId: number): Promise<any> {
    return Api.get(`firstfloor/${houseTypeId}/${houseSizeId}`)
  },
  getByHouseTypeIdAndHouseSizeIdAndRoofTypeId(houseTypeId: number, houseSizeId: number, roofTypeId: number): Promise<any> {
    return Api.get(`firstfloor/${houseTypeId}/${houseSizeId}/${roofTypeId}`)
  },
}
