import Axios from 'axios'

import {setupCache} from 'axios-cache-interceptor'
import {useEventBus} from '@vueuse/core'
import Logger from '../service/Logger'
import {eventBus} from '../eventBus'
const bus = useEventBus(eventBus)

const axios = setupCache(Axios)

// every error will trigger the error modal to show up
axios.interceptors.response.use(
  res => res,
  () => {
    bus.emit({name: 'apiError'})
  }
)

let requestStartTime: number

// const requestInterceptor = axios.interceptors.request.use(config => {
//   // If the route contains 'logzio', skip logging.
//   if (config.url && config.url.includes('logzio')) {
//     return config
//   }

//   // save time of request
//   requestStartTime = Date.now()
//   return config
// })

// const responseInterceptor = axios.interceptors.response.use(
//   response => {
//     // If the route contains 'logzio', skip logging.
//     console.log('Response:', response)
//     console.log('Res.Config:', response.config)
//     if (response.config.url && response.config.url.includes('logzio')) {
//       return response
//     }

//     // stop timer and calc duration
//     const duration = Math.round(Date.now() - requestStartTime)

//     Logger.sendApiInfo({
//       status: response.status,
//       statustext: response.statusText,
//       path: response.config.url || 'unknown',
//       duration: duration,
//       method: response.config.method?.toUpperCase() || 'unknown',
//     })

//     return response
//   },
//   error => {
//     // If the route contains 'logzio', skip logging.
//     if (error.response?.config?.url && error.response.config.url.includes('logzio')) {
//       throw error
//     }

//     Logger.sendApiInfo({
//       status: error.response?.status,
//       statustext: error.response?.statusText,
//       path: error.response?.config?.url || 'unknown',
//       duration: (Date.now() - requestStartTime) / 1000,
//       method: error.response?.config?.method?.toUpperCase() || 'unknown',
//     })

//     // Share the error so that other interceptors can handle it
//     throw error
//   }
// )

const ApiService = {
  _400interceptor: 0,
  _401interceptor: 0,
  _403interceptor: 0,
  _404interceptor: 0,
  _500interceptor: 0,
  _baseUrl: import.meta.env.VUE_APP_API_BASE_URL,

  get(resource: any, config?: any) {
    axios.defaults.baseURL = this._baseUrl

    if (config === undefined) config = {}

    config.validateStatus = function (status: number) {
      // return all codes below 400 as success.
      // also return 403 as success, because else its body is empty (axios problem)
      // and convert in the interceptor the 403 to an error
      return status < 400 || status === 403
    }

    return axios.get(resource, config)
  },

  post(resource: any, data: any, config?: any) {
    axios.defaults.baseURL = this._baseUrl

    if (config === undefined) {
      config = {}
    }
    config.validateStatus = function (status: number) {
      // return all codes below 400 as success.
      // also return 403 as success, because else its body is empty (axios problem)
      // and convert in the interceptor the 403 to an error
      return status < 400 || status === 403
    }

    return axios.post(resource, data, config)
  },

  put(resource: any, data: any, config?: any) {
    axios.defaults.baseURL = this._baseUrl
    if (config === undefined) {
      config = {}
    }
    config.validateStatus = function (status: number) {
      // return all codes below 400 as success.
      // also return 403 as success, because else its body is empty (axios problem)
      // and convert in the interceptor the 403 to an error
      return status < 400 || status === 403
    }

    return axios.put(resource, data, config)
  },

  patch(resource: any, data: any) {
    axios.defaults.baseURL = this._baseUrl
    return axios.patch(resource, data, {
      validateStatus: function (status: number) {
        // return all codes below 400 as success.
        // also return 403 as success, because else its body is empty (axios problem)
        // and convert in the interceptor the 403 to an error
        return status < 400 || status === 403
      },
    })
  },

  delete(resource: any) {
    axios.defaults.baseURL = this._baseUrl
    return axios.delete(resource, {
      validateStatus: function (status: number) {
        // return all codes below 400 as success.
        // also return 403 as success, because else its body is empty (axios problem)
        // and convert in the interceptor the 403 to an error
        return status < 400 || status === 403
      },
    })
  },

  use400Interceptor() {
    this._400interceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        if (error && error.request && error.request.status === 400) {
          // eslint-disable-next-line no-console
          console.error('error 400 intercepted')
          // here any interceptor handling for 400er:
        }
        throw error
      }
    )
  },

  use401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        if (error && error.request && error.request.status === 401) {
          // eslint-disable-next-line no-console
          console.error('error 401 intercepted')
          // here any interceptor handling for 401er:
        }
        throw error
      }
    )
  },

  use403Interceptor() {
    this._403interceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        if (error && error.request && error.request.status === 403) {
          // eslint-disable-next-line no-console
          console.error('error 403 intercepted')
          // here any interceptor handling for 403er:
        }
        throw error
      }
    )
  },

  use404Interceptor() {
    this._404interceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        if (error && error.request && error.request.status === 404) {
          // eslint-disable-next-line no-console
          console.error('error 404 intercepted')
          // here any interceptor handling for 404er:
        }
        throw error
      }
    )
  },

  use500Interceptor() {
    this._500interceptor = axios.interceptors.response.use(
      response => {
        return response
      },
      async error => {
        if (error && error.request && error.request.status === 500) {
          // eslint-disable-next-line no-console
          console.error('error 500 intercepted')
          // here any interceptor handling for 500er:
        }
        throw error
      }
    )
  },

  eject400Interceptor() {
    axios.interceptors.response.eject(this._400interceptor)
  },
  eject401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor)
  },
  eject403Interceptor() {
    axios.interceptors.response.eject(this._403interceptor)
  },
  eject500Interceptor() {
    axios.interceptors.response.eject(this._500interceptor)
  },
}

export default ApiService
